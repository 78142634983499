$extendable-input-width: 408px;
$extendable-input-border-color: $color-black-opacity;

.b-search {
	&.b-search--extendable {
		position: relative;
		width: auto;

		.b-search__wrapper-input {
			@extend %d-none;
			@include rem(width, $extendable-input-width);
			height: 35px;
			z-index: 10;
			position: absolute;
			right: 0;

			right: auto;
			left: -80px;
			top: -10px;
		}

		.b-search__label {
			position: absolute;
			// color: $search--label-color;
			margin: auto;
			top: 0;
			bottom: 0;
			@include rem(height, 35px);
			line-height: 35px;
			@include rem(left, 20px);
			cursor: text;

			font-size: 14px;
			@include font(400, FranklinGothicBook);
			color: $color-eclipse-opacity;

			.reportType--csr & {
				font-family: Arial;
				color: rgba($color-night-rider, 0.7);
			}
		}

		.b-search__input {
			width: 100%;
			height: 100%;
			padding: 0 35px 0 20px;
			margin-top: 0;

			width: 408px;
			height: 35px;
			border: 1px solid $extendable-input-border-color;

			font-size: 14px;
			@include font(400, FranklinGothicBook);
			color: $color-eclipse-opacity;

			&:focus {
				color: $color-eclipse;
			}
			.reportType--csr & {
				font-family: Arial;
				color: $color-night-rider;
				border-color: $color-very-light-grey;
				&:focus {
					color: $color-eclipse-opacity;
				}
			}
		}

		.b-search__button {
			display: block;
			padding: 0;
			background-color: transparent;
			color: $search--btn-icon;
			@include webfont-icon($webfont-icon--i-search);
			@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

			padding-right: 20px;
			@include font(500, FranklinGothic);
			font-size: 14px;
			line-height: 16px;
			position: relative;

			&:before {
				display: block;

				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				@include font-size(14px);
			}

			&:hover,
			&:focus {
				color: $search--btn-icon-hover;
			}

			&:active,
			&.is-expand {
				// transform: none;
				color: $search--btn-icon-active;
			}

			&:before {
				color: $color-suva-grey2;
			}
			&:hover,
			&:focus {
				&:before {
					color: $search--btn-icon-hover;
				}
			}
			&:active,
			&.is-expand {
				&:before {
					color: $search--btn-icon-active;
				}
			}
			&[disabled] {
				&:before {
					color: $color-suva-grey2;
				}
			}

			
			.reportType--csr & {
				font-family: Arial;
				color: rgba($color-nobel, 0.7);
				&:before {
					color: rgba($color-nobel, 0.7);
				}

				&:hover,
				&:focus {
					color: $search--btn-icon-hover;
				}

				&:active,
				&.is-expand {
					color: $search--btn-icon-active;
				}

				&:before {
					color: $color-suva-grey2;
				}
				&:hover,
				&:focus {
					&:before {
						color: $search--btn-icon-hover;
					}
				}
				&:active,
				&.is-expand {
					&:before {
						color: $search--btn-icon-active;
					}
				}
			}
			
		}

		.b-search__button-close {
			width: 35px;
			height: 35px;
			position: absolute;
			right: 0;
			top: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid #919191;

			.reportType--csr & {
				border-color: $color-nobel;
			}
		}

		.b-search__result {
			@include rem(width, $extendable-input-width);
		}

		@include breakpoint(sm) {
			.b-search__wrapper-input {
				left: auto;
				right: 0;
			}
		}
		@include breakpoint(xs) {
			.b-search__wrapper-input {
				position: fixed;
				top: 17px;
				left: 16px;
				width: calc(100% - 32px);
			}
		}
	}
}
