.b-tabs--horizontal {
	.b-tabs {
		&__button {

		}
	}
}

@if ($responsive) {
	@include breakpoint(sm) {
		.b-tabs--horizontal {
			.col--no-gutter {
				padding-left: 0;
				padding-right: 0;
			}

			.b-tabs__nav-item {
				border-left: none;
				border-top: 1px solid $color-white;

				&:first-child {
					border-top: none;
				}
			}
		}
	}
}
