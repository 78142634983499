$bod_bod-animated-blocks--content-bg: $color-eallery;
$bod_bod-animated-blocks--content-arrow: $color-white;
$bod_bod-animated-blocks--name-color: $color-pumpkin;

$bod_bod-animated-blocks--photo-width: 192px;
$bod_bod-animated-blocks--photo-full-width: 212px;

.b-bod--animated-blocks {
	p {
		margin-top: 0;
	}

	%content-arrow,
	.content-arrow {
		content: '';
		width: 0;
		height: 0;
		border-left-color: transparent;
		border-right-color: transparent;
		border-top-color: $bod_bod-animated-blocks--content-arrow;
		border-left-style: solid;
		border-right-style: solid;
		border-top-style: solid;
		@include rem(border-left-width, 20px);
		@include rem(border-right-width, 20px);
		@include rem(border-top-width, 15px);
		position: absolute;
		top: 0;
	}

	.b-bod-main {
		position: relative;
		cursor: pointer;
		text-align: left;
		@include rem(margin-bottom, 7px);

		&__photo {
			@include rem(width, $bod_bod-animated-blocks--photo-width);
			@include rem(height, $bod_bod-animated-blocks--photo-width);
			margin: auto;
			@include rem(margin-bottom, 35px);
			position: relative;
			right: 0;
			left: 0;
			transition: all .5s ease;

			img {
				@include rem(width, $bod_bod-animated-blocks--photo-width);
				@include rem(height, $bod_bod-animated-blocks--photo-width);
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				transition: all 0.5s;
			}

			&:after {
				content: '';
				position: absolute;
				left: auto;
				width: 100%;
				height: 8px;
				background-color: $color-supernova;
				display: block;
				bottom: 0;
			}
		}

		&.is-arrowed {
			&:before {
				@extend .content-arrow;
				left: 70px;
				right: 0;
				top: 98%;
				z-index: 1;
				margin-top: 10px;
			}
		}

		&.is-static, &.no-content {
			cursor: default;
		}

		&:hover,
		&.is-expand,
		&.is-static {
			.b-bod-main__photo {
				@include rem(width, $bod_bod-animated-blocks--photo-full-width);
				@include rem(height, $bod_bod-animated-blocks--photo-full-width);
				@include rem(margin-bottom, 15px);

				img {
					@include rem(width, $bod_bod-animated-blocks--photo-full-width);
					@include rem(height, $bod_bod-animated-blocks--photo-full-width);
				}

				&:after {
					width: 100%;
				}
			}
		}

		&__name {
			@include font-size(18px);
			@include rem(line-height, 18px);
			color: $bod_bod-animated-blocks--name-color;
			margin-bottom: 0;
			text-align: center;
			margin: auto;
			max-width: $bod_bod-animated-blocks--photo-full-width;
		}

		&__post {
			@include font-size(16px);
			color: $color-black;
			margin-top: 0;
			margin-bottom: 0;
			text-align: center;
			margin: auto;
			max-width: $bod_bod-animated-blocks--photo-full-width;
		}
	}

	.b-bod-main.no-content {
		.b-bod-main__photo {
			&:before {
				content: '';
				position: absolute;
				left: 0;
				width: 16%;
				height: 8px;
				background-color: $color-supernova;
				display: block;
				bottom: 0;
				z-index: 1;
			}
			&:after {
				content: '';
				position: absolute;
				right: 0;
				width: 84%;
				height: 8px;
				background-color: $color-pumpkin;
				display: block;
				bottom: -8px;
			}
		}
	}

	.b-bod-content {
		width: 100%;
		// background: $color-eallery;
		position: relative;
		@include rem(padding, 25px);
		display: none;
		margin-top: 20px;
		border: 1px solid $color-supernova;

		&.is-static {
			display: block;
		}

		h3 {
			@include font-size(18px);
		}

		&__icon {
			display: inline-block;
		}
	}
}

.b-bod-legend {
	display: inline-block;
	text-align: center;

	&__icon {
		width: 58px;
		margin: auto;
	}

	&__text {
		width: 100px;
		margin: auto;
	}

	&--margin-t-bod {
		// margin-left: 70px;
		@include breakpoint(sm) {
			// margin-left: 20px;
		}
	}
}

@include breakpoint(sm) {
	.b-bod--animated-blocks {
		.b-bod-main {
			&__photo {
				margin-left: auto;
				margin-right: auto;
				text-align: center;

				img {
					margin: 0 auto;
				}
			}

			&__name {
				text-align: center;
			}

			&__post {
				text-align: center;
			}

			&.is-arrowed {
				&:before {
					margin-left: auto;
					margin-right: auto;
					left: 0;
				}
			}
		}
	}
}

@include breakpoint(sm) {
	.margin-top--small {
		margin-top: 30px;
		// width: 100%;
	}
}

.bod-circle {
	height: 20px;
	width: 20px;
	border-radius: 50%; 

	&--orange {
			background-color: orange;	
	}
    
}
