$copyright_base--color: $color-eclipse;
$copyright_base--link-color: $color-eclipse;
$copyright_base--link-color-hover: $link-hover-color;
$copyright_base--link-color-active: $link-active-color;

@if ($contrast) {
	$copyright_base--color: $color-white;
	$copyright_base--link-color: $color-white;
	$copyright_base--link-color-hover: $color-white;
}

.b-copyright {
	color: $copyright_base--color;
    font-size: 18px;
    line-height: 20px;

    margin-right: 35px;

	&__name--link {
		color: $copyright_base--link-color;
		text-decoration: none;
		background: transparent;

		&:visited {
			color: $copyright_base--link-color;
		}

		&:hover,
		&:focus,
		&:visited:hover {
			color: $copyright_base--link-color-hover;
			text-decoration: none;
		}

		&:active {
			color: $copyright_base--link-color-active;
		}
		.reportType--csr & {
			font-family: Arial;
			color: $color-night-rider;

			&:visited {
				color: $color-night-rider;
			}
	
			&:hover,
			&:focus,
			&:visited:hover {
				color: $color-night-rider;
				text-decoration: none;
			}
	
			&:active {
				color: $color-night-rider;
			}
		}
	}

	.reportType--csr & {
		font-family: Arial;
		color: $color-night-rider;
	}
}
