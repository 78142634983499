.body--map {
	.l-layout-wrapper--1 {
		.l-footer {
			padding-top: 15px;
			padding-bottom: 15px;
		}

		.l-header {
			padding-bottom: 0;
		}

		.l-page {
			padding: 0;
		}

		.l-page__content {
			padding-bottom: 0;
		}
	}

	.tooltipster-sidetip.tooltipster-tooltips {
		.tooltipster-box {
			font-weight: 500;
			border-radius: 3px;
			box-shadow: 0 0 18px #525252;
			background-color: #ffffff;
			padding: 0 0 1px 0;
		}

		&.tooltipster-bottom .tooltipster-arrow-background {
			border-bottom-color: #ffffff;
		}

		&.tooltipster-left .tooltipster-arrow-background {
			border-left-color: #ffffff;
		}

		&.tooltipster-right .tooltipster-arrow-background {
			border-right-color: #ffffff;
		}

		&.tooltipster-top .tooltipster-arrow-background {
			border-top-color: #ffffff;
		}
	}
}

.map {
	position: absolute;
	width: 100vw;
	height: 100%;
	min-width: 100%;
	left: 50%;
	transform: translateX(-50%);
	background-color: #58595B;
	overflow: hidden;

	&__title {
		position: absolute;
		top: 0;
		width: 100%;
		text-align: center;
		background-color: #e2e2e2;
		padding: 12px 15px;
		font-size: 22px;
		font-weight: bold;
		line-height: 1.2;
		z-index: 2;
	}

	&__inner {
		height: 100%;
	}

	&__top {
		flex-grow: 1;
		max-height: 100%;
		height: 100%;
		overflow: hidden;
	}

	&__bottom {
		position: absolute;
		bottom: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
	}

	&__svg {
		min-width: 1750px;
		height: 100%;
	}

	.preloader {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 100;

		&:before {
			background-color: #58595B;
		}
	}

	@media (max-width: 450px) {
		&__title {
			padding: 12px;
			font-size: 18px;
		}
	}
}

.map-filter {
	display: flex;
	align-items: flex-end;
	width: 100%;
	height: 100%;

	&__item {
		flex-basis: 100%;
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
		padding: 0 6px;
		height: 60px;
		border-top: 8px solid;
		cursor: pointer;
		transition: background-color 0.2s ease;
		background-color: #e6e7e9;

		&--all {
			border-top-color: #ffffff;
		}

		&--america {
			border-top-color: #FCB53A;
		}

		&--africa {
			border-top-color: #FCEE23;
		}

		&--europe {
			border-top-color: #BDBEC0;
		}

		&--cis {
			border-top-color: #4E4E50;
		}

		&--russia {
			border-top-color: #F57E27;
		}

		&--asia {
			border-top-color: #EE3426;
		}

		&.is-active {
			background-color: #d1d2d4;
		}
	}

	@media (max-width: 600px) {
		&__button {
			font-size: 12px;
			border-width: 5px;
			height: 44px;
		}
	}

	@media (max-width: 380px) {
		&__button {
			font-size: 11px;
			height: 38px;
		}
	}
}

@keyframes handMove {
	0%, 100% {
		transform: translateX(40px);
	}

	50% {
		transform: translateX(135px);
	}
}

.map-help {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	margin: auto;
	pointer-events: none;
	z-index: 1;

	&:before {
		content: '';
		position: absolute;
		background-color: black;
		width: 100%;
		height: 100%;
		opacity: 0.5;
	}

	&__svg {
		position: absolute;
		display: inline-block;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&__svg-hand {
		animation: handMove 2s ease-in-out infinite;
	}

	.is-help & {
		display: block;
	}
}

#map-svg {
	position: absolute;
	overflow: visible !important;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	#america,
	#africa,
	#europe,
	#cis,
	#russia,
	#asia {
		opacity: 0;
		transition: opacity 0.2s ease;

		.b-tooltip {
			pointer-events: none;
		}
	}

	&.group-all {
		#america,
		#africa,
		#europe,
		#cis,
		#russia,
		#asia {
			opacity: 1;
		}

		.b-tooltip {
			pointer-events: auto !important;
		}
	}

	&.group-america {
		#america {
			opacity: 1;

			.b-tooltip {
				pointer-events: auto;
			}
		}
	}

	&.group-africa {
		#africa {
			opacity: 1;

			.b-tooltip {
				pointer-events: auto;
			}
		}
	}

	&.group-europe {
		#europe {
			opacity: 1;

			.b-tooltip {
				pointer-events: auto;
			}
		}
	}

	&.group-cis {
		#cis {
			opacity: 1;

			.b-tooltip {
				pointer-events: auto;
			}
		}
	}

	&.group-russia {
		#russia {
			opacity: 1;

			.b-tooltip {
				pointer-events: auto;
			}
		}
	}

	&.group-asia {
		#asia {
			opacity: 1;

			.b-tooltip {
				pointer-events: auto;
			}
		}
	}

	.map.is-help & {
		.b-tooltip {
			pointer-events: none !important;
		}
	}
}

.group-map {
	&__text {
		display: inline-block;
		color: #000000;
		font-size: 14px;

		& > i {
			color: #6D6E70;
		}
	}

	&__icon {
		display: flex;
		align-items: center;
		margin-top: 5px;

		& + .group-map__text {
			margin-top: 12px;
		}
	}

	&__icon-text {
		color: #6D6E70;
		margin-left: 6px;
	}
}
