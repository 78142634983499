.b-lang--single {
	.b-lang__item {
		display: block;
		color: $lang-selector--color;
		text-decoration: none;
		font-size: 14px;
		text-transform: uppercase;
		// @extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

		// &:before {
		// 	display: block;
		// 	@include font-size(22px);
		// }

		&:hover,
		&:focus {
			color: $lang-selector--color-hover;
			background-color: transparent;
		}

		&:active {
			color: $lang-selector--color-active;
			background-color: transparent;
			transform: translateY(1px);
		}

		// &--ru {
		// 	@include webfont-icon($webfont-icon--lang-ru);
		// }

		// &--en {
		// 	@include webfont-icon($webfont-icon--lang-en);
		// }
	}
}
