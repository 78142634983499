.b-useful-links--static {
	.b-useful-links {
		&__title {
			font-size: 16px;
			line-height: 19px;
			@include font(900, 'Humanist');
			background-color: $color-supernova;
			color: $color-white;
			@include webfont-icon($webfont-icon--i-arrow-accordion, 'after');
			text-transform: uppercase;
			width: auto;
			display: inline-block;
			margin-left: 5px;
			padding: 5px 14px;
			margin-bottom: 0;

			.reportType--csr & {
				background-color: $color-supernova-kco;
				font-family: Arial;
			}

			&::after {
				font-size: 7px;
				margin-left: 5px;
				position: relative;
				top: -3px;
			}
		}

		&__list {
			@include rem(padding-top, 10px);
		}

		&__item {
			padding-left: 12px;
		}
	}
}
