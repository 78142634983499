$btn--padding-vertical: 0.667em; // отступы по сверху и снизу кнопок
$btn--padding-horizontal: 0.567em; // отступы по краям кнопок

button {
	border: 0;

	&:focus {
		outline: 0;
	}

	&.focus-on:focus {
		outline-width: 1px;
	}
}

%button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	line-height: 1;
	color: $btn--color;
	border: 2px solid $btn--bg;
	// background-color: $btn--bg;
	cursor: pointer;
	transition: transform 0.2s ease, color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
	@include font(500, 'FranklinGothic');
	font-size: 16px;
	line-height: 18px;

	.reportType--csr & {
		font-family: Arial;
	}

	&:hover {
		background-color: $btn--bg-hover;
		color: $btn--color-hover;
	}

	&:focus {
		outline: 0;
	}

	&:active {
		background-color: $btn--bg-active;
		color: $btn--color-active;
		border-color: $btn--bg-active;
		transform: translateY(1px);

		.reportType--csr & {
			background-color: $color-supernova-kco;
		}
	}

	&:visited {
		background-color: $btn--bg-visited;
		color: $btn--color-active;
	}
}

button[disabled="disabled"],
button:disabled {
	@extend .btn--disabled;
}

.btn {
	@extend %button;
	padding: $btn--padding-vertical $btn--padding-horizontal;
	text-decoration: none;

	&__text {
		position: relative;
		line-height: 1;
		z-index: 1;
	}
}

input[type="submit"] {
	@extend %button;
}

.btn--disabled {
	color: #919191;
	border-color: #919191;
	// opacity: 0.3;
	pointer-events: none;
	cursor: default;

	&:hover {
		color: $btn--color;
		background-color: $btn--bg;
	}
}

.btn .webfont-icon {
	margin: 0 0.4em;
}
