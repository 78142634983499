$text-color: $color-white;

.b-not-page {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: calc(100vh - 139px - 145px);
	min-height: 312px;

	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		max-width: 489px;
		width: 100%;
		padding: 69px 0 61px;
	}

	&__image-wrapper {
		@include font(700);
		font-size: 100px;
		line-height: normal;
		text-align: center;
		text-transform: uppercase;
		color: $not-page-404;
	}

	&__info {
		max-width: 392px;
		width: 100%;
		margin: 0 auto;
	}

	&__text {
		font-size: 16px;
		line-height: 26px;
		text-align: center;

		&--link {
			font-size: 16px;
			line-height: 26px;
			text-align: center;
			margin: 0;
			text-transform: none;
			padding-left: 8px;
		}
	}

	&__link {
		font-size: 16px;
		line-height: 26px;
		text-align: center;
		margin-right: 34px;
	}
}
