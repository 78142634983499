$menu_horizontal-cols--bg-sub-space: $color-white;
$menu_horizontal-cols--bg-list-lvl2: $color-white;
$menu_horizontal-cols--bg-expand-hover: $color-white;
$menu_horizontal-cols--font-expand-hover: $color-pumpkin;
$menu_horizontal-cols--font-expand-active: $color-supernova;

.b-nav--top.b-nav--horizontal-cols {
	padding-top: 5px;
	padding-bottom: 1px;

	.b-nav__list--lvl1 {
		display: flex;
		height: 100%;

		.reportType--csr & {
			padding-right: 50px;
			max-width: 1153px;
			width: 100%;
			justify-content: space-between;

			// @media (max-width: 1680px) {
			// 	max-width: 68%;
			// }

			// @media (max-width: 1440px) {
			// 	max-width: 76%;
			// }

			// @media (max-width: 1366px) {
			// 	max-width: 80%;
			// }

			// @media (max-width: 1280px) {
			// 	max-width: 87%;
			// }

			// @media (max-width: 1054px) {
			// 	max-width: 95%;
			// }
		}
	}

	.b-nav__list--lvl2 {
		display: block;
		position: relative;
		height: auto;
		background-color: $menu_horizontal-cols--bg-list-lvl2;
		z-index: 20;
		@include rem(padding-right, 35px);
		margin-bottom: 1.3em;//50px;
	}

	// .b-nav__list--lvl3 {
	// 	margin-top: 13px;
	// }

	.b-nav__list--lvl4 {
		margin: 7px 0 14px;
	}

	.b-nav__sub-content-wrapper {
		@include rem(padding, 20px 0);
	}

	.b-nav__section {
		width: calc((100%/3) - 25px);
	}

	.b-nav__sub-footer {
		&:after {
			content: "";
			background: #EE2D24;
			height: 5px;
			bottom: -5px;
			position: absolute;
			left: 50%;
			width: 100vw;
			margin-left: -50vw;

			.reportType--csr & {
				background: $color-harley-davidson-orange;
			}
		}
	}

	.b-nav__sub-space {
		display: none;
		position: absolute;
		left: 0;
		width: 100%;
		background-color: $menu_horizontal-cols--bg-sub-space;
		padding: 58px 0 0 20px;
		z-index: 12;

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			height: 100%;
			top: 0;
			width: 9999px;
			background-color: $menu_horizontal-cols--bg-sub-space;
		}

		&:before {
			right: 100%;
		}

		&:after {
			left: 100%;
		}
	}

	.b-nav__sub-content-wrapper {
		padding-right: 110px;
	}

	.b-nav__item {
		display: block;
		// margin-right: 56px;
		margin-right: 70.5px;

		&:last-child {
			margin-right: 0;
		}

		.reportType--csr & {
			// margin-right: 30.5px;
			// width: calc(100%/8);
			margin-right: 20.5px;

			.browser-ie & {
				// flex: 1 0 0px;
				// flex: 1 0 0%;
				overflow: hidden;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.b-nav__item--lvl2 {
		position: relative;
	}

	.b-nav__item--lvl2 + .b-nav__item--lvl2 {
		margin-top: 1.3em;
	}

	.b-nav__item--lvl3 {
		text-align: left;
		position: relative;

		&:not(:first-child) {
			margin-top: -5px;
		}
	}

	.b-nav__trigger {
		display: inline-flex;
		width: 30px;
		height: 30px;
		position: relative;
		align-items: center;
		justify-content: center;

		@include webfont-icon($webfont-icon--i-menu);
		&:before {
			font-size: 10px;
		}
		&:hover,
		&:focus {
			color: $menu_horizontal-cols--font-expand-hover;
			background: transparent;

			&:before {
				color: $menu_horizontal-cols--font-expand-hover;
			}
		}

		&:active {
			color: $menu_horizontal-cols--font-expand-active;
			background: transparent;
			&:before {
				// transform: rotate(180deg);
				color: $menu_horizontal-cols--font-expand-active;
			}
		}

		&.open {
			&:before {
				transform: rotate(180deg);
				color: $menu_horizontal-cols--font-expand-active;
			}
		}
	}

	.b-nav__link {
		width: 100%;
		height: 100%;
		color: $menu--link-color;
		@include font-size($menu--font-size);
		background: transparent;

		&:hover,
		&:focus {
			color: $menu_horizontal-cols--font-expand-hover;
			background: transparent;

			& + .b-nav__trigger:before {
				color: $menu_horizontal-cols--font-expand-hover;
			}
		}

		&:active {
			color: $menu_horizontal-cols--font-expand-hover;
			background: transparent;
			& + .b-nav__trigger:before {
				// transform: rotate(180deg);
				color: $menu_horizontal-cols--font-expand-hover;
			}
		}

		span {
			display: inline-block;
			width: 100%;
			vertical-align: middle;

			.reportType--csr & {
				font-family: Arial;
			}
		}
		.reportType--csr & {
			font-family: Arial;
		}
	}
	.open > .b-nav__link--lvl3 {
		color: $menu_horizontal-cols--font-expand-active;
		background: transparent;
	}

	.b-nav__link--lvl1 {
		color: $menu--link-color;
		position: relative;
		display: flex;
		align-items: center;
		// text-align: center;
		text-decoration: none;

		@include font(600, FranklinGothic);
		font-size: 18px;
		line-height: 20px;
		// padding: 4px 10px 8px;
		padding: 3px 0px 6px;

		.reportType--csr & {
			font-size: 16px;
			line-height: 18px;

			font-family: Arial;

			&:hover,
			&:focus {
				color: $color-supernova-kco;
			}

			&.current {
				color: $color-pumpkin-kco;

				&:hover,
				&:focus {
					color: $color-supernova-kco;
				}
			}

			&.is-expand {
				color: $color-supernova-kco;
				background-color: $menu_horizontal-cols--bg-expand-hover;
			}

		}

		span {
			// border: 1px solid transparent;
			padding-top: 1px
		}

		&:hover,
		&:focus {
			color: $menu--link-color-hover;
			// background-color: $menu_horizontal-cols--bg-expand-hover;
			// span {
			// 	border: 1px solid $color-white;
			// }
		}

		&.current {
			color: $color-supernova;

			&:hover,
			&:focus {
				color: $menu--link-color-hover;

				// span {
				// 	border: 1px solid $color-white;
				// }
			}
		}

		&.is-expand {
			color: $menu_horizontal-cols--font-expand-hover;
			background-color: $menu_horizontal-cols--bg-expand-hover;
		}
	}

	.b-nav__link--lvl2 {
		line-height: 1;
		padding-left: 0;
		text-align: left;
		display: inline-block;
		position: relative;

		@include font(600, FranklinGothic);
		font-size: 20px;
		line-height: 23px;

		color: $color-eclipse;

		&:hover,
		&:focus {
			color: $color-pumpkin;
		}

		.current {
			color: $color-supernova;
		}

		.reportType--csr & {
			font-size: 18px;
			line-height: 21px;
			&:hover,
			&:focus {
				color: $color-supernova-kco;
			}

			&.current {
				color: $color-pumpkin-kco;

				&:hover,
				&:focus {
					color: $color-supernova-kco;
				}
			}

			&.is-expand {
				color: $color-pumpkin-kco;
				background-color: $menu_horizontal-cols--bg-expand-hover;
			}

		}
	}

	.b-nav__link--lvl3 {
		display: inline-block;
		@include font(400, FranklinGothicBook);
		font-size: 18px;
		line-height: 32px;
		width: auto;
		color: $color-eclipse;

		&:hover,
		&:focus {
			color: $color-pumpkin;
		}

		&.current {
			color: $color-supernova;
		}

		.reportType--csr & {
			font-size: 16px;
			line-height: 30px;
		}
	}

	.b-nav__link--lvl4 {
		padding-left: 22px;
		display: inline-block;
		@include font(400, FranklinGothicBook);
		font-size: 16px;
		line-height: 18px;
		margin-bottom: 5px;
		color: $color-eclipse;

		&:hover,
		&:focus {
			color: $color-pumpkin;
		}

		&.current,
		&:active {
			color: $color-supernova;
		}
	}
}

@include breakpoint(lg) {
	.b-nav--top.b-nav--horizontal-cols {
		.b-nav__item--lvl1 {
			margin-right: 30px;
		}

		.b-nav__sub-content-wrapper {
			padding-right: 0;
		}
	}
}

@include breakpoint(md) {
	.b-nav--top.b-nav--horizontal-cols {
		.b-nav__link--lvl1 {
			text-align: left;
			font-size: 16px;
			line-height: 16px;
			padding: 1px 0 5px;

			.reportType--csr & {
				font-size: 14px;
				line-height: 14px;
			}
		}
		.b-nav__item--lvl1 {
			margin-right: 0;
			width: 100px;
		}
		.b-nav__list--lvl1 {

			margin-right: -10px;
			justify-content: space-between;
		}
		.b-nav__sub-content-wrapper {
			padding-right: 70px;
			// padding-bottom: 55px;
		}
		.b-nav__sub-space {
			padding: 29px 0 0 0;
		}
	}
}

@include breakpoint(sm) {

}

@media (max-width: 640px) {
	.b-nav--top.b-nav--horizontal-cols {
		.b-nav__item--lvl1 {
			margin-right: 10px;
			width: auto;
		}
	}
}
