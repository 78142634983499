$mobile-menu_vertical-accordeon--font-size: 15px;
$mobile-menu_vertical-accordeon--button-width: 30px;               // The width of the button area
$mobile-menu_vertical-accordeon--button-height: 30px;                   // The height of the button area
$mobile-menu_vertical-accordeon--bar-thickness: 2px;                    // The thickness of the button bars
$mobile-menu_vertical-accordeon--bar-space: 7px;                 // The spacing between button bars
$mobile-menu_vertical-accordeon--transistion-duration: 0.3s;     // The transition duration
$mobile-menu_vertical-accordeon--bar-width: 20px;

$mobile-menu_vertical-accordeon--panel-bg: $color-white;
$mobile-menu_vertical-accordeon--link-color: $base-text-color;
$mobile-menu_vertical-accordeon--link-color-hover: $color-supernova;
$mobile-menu_vertical-accordeon--link-color-active: $color-supernova;
$mobile-menu_vertical-accordeon--controller-color: $color-black;
$mobile-menu_vertical-accordeon--controller-color-hover: $color-supernova;
$mobile-menu_vertical-accordeon--controller-color-active: $color-supernova;
$mobile-menu_vertical-accordeon--button-bg: transparent;
$mobile-menu_vertical-accordeon--button-bg-hover: transparent;
$mobile-menu_vertical-accordeon--button-bg-active: transparent;
$mobile-menu_vertical-accordeon--button-color: $color-eclipse;
$mobile-menu_vertical-accordeon--button-color-hover: $color-eclipse;
$mobile-menu_vertical-accordeon--button-color-active: $color-eclipse;
$mobile-menu_vertical-accordeon--link-lvl1-bg: #F5F5F5;
$mobile-menu_vertical-accordeon--link-lvl2-bg: transparent;
$mobile-menu_vertical-accordeon--link-lvl3-bg: transparent;

@if ($contrast) {
	$mobile-menu_vertical-accordeon--panel-bg: $color-eallery;
	$mobile-menu_vertical-accordeon--link-color: $base-text-color;
	$mobile-menu_vertical-accordeon--link-color-hover: $color-black;
	$mobile-menu_vertical-accordeon--link-color-active: orange;
	$mobile-menu_vertical-accordeon--controller-color: $base-text-color;
	$mobile-menu_vertical-accordeon--controller-color-hover: $color-black;
	$mobile-menu_vertical-accordeon--controller-color-active: orange;
	$mobile-menu_vertical-accordeon--button-bg: transparent;
	$mobile-menu_vertical-accordeon--button-bg-hover: transparent;
	$mobile-menu_vertical-accordeon--button-bg-active: transparent;
	$mobile-menu_vertical-accordeon--button-color: $color-ironside-gray;
	$mobile-menu_vertical-accordeon--button-color-hover: $color-black;
	$mobile-menu_vertical-accordeon--button-color-active: orange;
	$mobile-menu_vertical-accordeon--link-lvl1-bg: $color-light-gray;
	$mobile-menu_vertical-accordeon--link-lvl2-bg: #eee;
	$mobile-menu_vertical-accordeon--link-lvl3-bg: #fff;
}

.b-mobile-menu.b-mobile-menu--vertical-accordion {
	@include rem(width, $mobile-menu_vertical-accordeon--button-width);
	@include rem(height, $mobile-menu_vertical-accordeon--button-height);

	.b-mobile-menu__burger {
		display: block;
		z-index: 10;
		position: relative;
		background-color: $mobile-menu_vertical-accordeon--button-bg;
		@include rem(width, $mobile-menu_vertical-accordeon--button-width);
		@include rem(height, $mobile-menu_vertical-accordeon--button-height);

		&:hover,
		&:focus {
			background-color: $mobile-menu_vertical-accordeon--button-bg-hover;

			.b-mobile-menu__burger-ingredients-middle,
			.b-mobile-menu__burger-ingredients:before,
			.b-mobile-menu__burger-ingredients:after {
				background-color: $mobile-menu_vertical-accordeon--button-color-hover;
			}
		}

		&:active {
			background-color: $mobile-menu_vertical-accordeon--button-bg-active;

			.b-mobile-menu__burger-ingredients-middle,
			.b-mobile-menu__burger-ingredients:before,
			.b-mobile-menu__burger-ingredients:after {
				background-color: $mobile-menu_vertical-accordeon--button-color-active;
			}
		}

		.b-mobile-menu__burger-ingredients {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: $mobile-menu_vertical-accordeon--bar-thickness;
			width: $mobile-menu_vertical-accordeon--bar-width;
			margin: auto;

			&:before,
			&:after {
				content: '';
				position: absolute;
				display: block;
				left: 0;
				width: 100%;
				height: $mobile-menu_vertical-accordeon--bar-thickness;
				background-color: $mobile-menu_vertical-accordeon--button-color;
				transition-duration: $mobile-menu_vertical-accordeon--transistion-duration, $mobile-menu_vertical-accordeon--transistion-duration;
				transition-delay: $mobile-menu_vertical-accordeon--transistion-duration, 0s;
				will-change: transform;
			}

			&:before {
				top: -$mobile-menu_vertical-accordeon--bar-thickness - $mobile-menu_vertical-accordeon--bar-space;
				/* autoprefixer: off */
				transition-property: top, transform;
			}

			&:after {
				bottom: -$mobile-menu_vertical-accordeon--bar-thickness - $mobile-menu_vertical-accordeon--bar-space;
				/* autoprefixer: off */
				transition-property: bottom, transform;
			}
		}

		.b-mobile-menu__burger-ingredients-middle {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: $mobile-menu_vertical-accordeon--button-color;
			transition: opacity 0s $mobile-menu_vertical-accordeon--transistion-duration;
			will-change: transform;
		}

		&.is-expand {
			position: fixed;
			z-index: 99;
			background: none;
			
			&:before {
				content: "";
				background: $color-white;
				width: 100vw;
				height: 84px;
				display: block;
				position: absolute;
				right: -7px;
				top: -46px;
			}

			.b-mobile-menu__burger-ingredients-middle {
				opacity: 0;
			}

			.b-mobile-menu__burger-ingredients {
				&:before,
				&:after {
					transition-delay: 0s, $mobile-menu_vertical-accordeon--transistion-duration;
				}

				&:before {
					top: 0;
					transform: rotate(45deg);
				}

				&:after {
					bottom: 0;
					transform: rotate(-45deg);
				}
			}

			.b-mobile-menu__text {
				opacity: 1;
			}
		}
	}

	.b-mobile-menu__text {
		font-size: 14px;
		line-height: 16px;
		color: $color-eclipse;
		@include font(500, 'FranklinGothic');
		position: absolute;
		right: 100%;
		top: 6px;
		width: 71px;
		opacity: 0;
		transition: opacity opacity 0s $mobile-menu_vertical-accordeon--transistion-duration;
	
		.reportType--csr & {
			font-family: Arial;
		}
	}

	.b-mobile-menu__panel {
		display: none;
		position: fixed;
		height: calc(100% - 84px);
		width: 100%;
		left: 0;
		top: 84px;
		z-index: 100;
		background-color: $mobile-menu_vertical-accordeon--panel-bg;
		border-bottom: 5px solid $color-cinnabar;

		&::-webkit-scrollbar {
			background: transparent;
			width: 8px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: #555;
			background-color: rgba(0, 0, 0, 0.4);
		}

		.reportType--csr & {
			border-color: $color-harley-davidson-orange;
		}
	}

	.b-mobile-menu__item {
		display: block;
		margin-bottom: 15px;
	}

	.b-mobile-menu {
		&__panel-item {
			&--search {
				display: flex;
				justify-content: space-between;
				padding: 14px 16px 0;
				margin-bottom: 54px;

				position: absolute;
				top: -86px;
				width: 100%;
			}

			&--menu {
				border-top: 2px solid $color-black;
				overflow: hidden;
				height: calc(100% - 83px);

				// &:before {
				// 	content: "";
				// 	background: $color-white;
				// 	width: 100%;
				// 	height: 84px;
				// 	display: block;
				// 	position: absolute;
				// }
			}

			&--footer {
				padding: 0 16px 0;
			}
		}
	}

	.b-mobile-menu__item--search {
		padding: 0 20px;
		text-align: center;
		padding-left: 15px;
		padding-right: 15px;

		.search.search--inner .search__input {
			width: 100%;
		}

		.search {
			display: inline-block;
			width: 100%;
		}
	}

	.b-mobile-menu__item--menu {
		width: 100%;
	}

	.b-nav {
		padding: 0;
	}

	.b-nav__list {
		.b-nav__list {
			display: none;
		}

		&--lvl2 {
			display: block !important;
		}

		&.is-expand {
			display: block;
		}
	}

	.b-nav__item--lvl1 + .b-nav__item--lvl1 {
		margin-top: 1px;
	}

	.b-nav__item {
		&--lvl1 {
			background-color: $mobile-menu_vertical-accordeon--link-lvl1-bg;
			padding: 0 16px;

			&:nth-child(2n) {
				background-color: transparent;
			}
		}
	}

	.b-nav__link {
		display: block;
		@include rem(padding-right, 15px);
		@include rem(padding-top, 5px);
		@include rem(padding-bottom, 5px);
		color: $mobile-menu_vertical-accordeon--link-color;
		@include font-size($mobile-menu_vertical-accordeon--font-size);
		text-decoration: none;
		@include font(400);
		line-height: 1;

		&:hover {
			color: $mobile-menu_vertical-accordeon--link-color-hover;
			background-color: transparent !important;
		}

		&:active {
			color: $mobile-menu_vertical-accordeon--link-color-active;
			background-color: transparent !important;
		}

		&.current {
			color: $color-supernova;
		}
	}

	.b-nav__controller {
		position: absolute;
		@include rem(width, 12px);
		display: block;
		top: 1px;
		right: 6px;
		margin: auto 0;
		bottom: 0;
		cursor: pointer;
		@include webfont-icon($webfont-icon--i-arrow-accordion);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&:before {
			display: block;
			color: $mobile-menu_vertical-accordeon--controller-color;
			font-size: 12px;
			margin: auto;
			transition: transform 0.2s ease;
			@include rem(border-radius, 5px);
		}

		&:hover {
			&:before {
				color: $mobile-menu_vertical-accordeon--controller-color-hover;
			}
		}

		&:active {
			&:before {
				color: $mobile-menu_vertical-accordeon--controller-color-active;
			}
		}

		&.is-expand:before {
			color: $color-supernova;
			transform: rotate(180deg);
		}
	}

	.b-nav__link--lvl1 {
		font-size: 20px;
		line-height: 23px;
		@include font(500, 'FranklinGothic');
		padding-top: 12px;
		padding-bottom: 19px;

		&:hover {
			color: $mobile-menu_vertical-accordeon--link-color-hover;
		}

		&:active {
			color: $mobile-menu_vertical-accordeon--link-color-active;
		}

		.reportType--csr & {
			font-family: Arial;

			&:hover,
			&:focus {
				color: $color-supernova-kco;
			}

			&.current {
				color: $color-pumpkin-kco;

				&:hover,
				&:focus {
					color: $color-supernova-kco;
				}
			}

			&.is-expand {
				color: $color-pumpkin-kco;
			}
		}
	}

	.b-nav__link--lvl1.has-submenu + .b-nav__controller {
		display: none;
	}

	.b-nav__link-wrapper {
		position: relative;
	}

	.b-nav__link--lvl2 {
		font-size: 18px;
		line-height: 32px;

		.reportType--csr & {
			font-family: Arial;

			&:hover,
			&:focus {
				color: $color-supernova-kco;
			}

			&.current {
				color: $color-pumpkin-kco;

				&:hover,
				&:focus {
					color: $color-supernova-kco;
				}
			}

			&.is-expand {
				color: $color-pumpkin-kco;
			}
		}
	}

	.b-nav__link--lvl3 {
		font-size: 16px;
		line-height: 22px;
		padding-left: 20px;
		padding-top: 2px;
		padding-bottom: 0px;
	}

	.b-nav__link--lvl4 {
		@include rem(padding-left, 60px);
	}
}
