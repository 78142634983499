.b-form--privacy {
	.b-form__field-title {
		font-size: 16px;
		@include font(700);
		margin-bottom: 0.2em;

		&--main {
			font-size: 18px;
			color: #ef7918;
			@include font(600);
			text-transform: uppercase;
			margin-top: 15px;
			margin-bottom: 12px;
		}
	}

	.b-form__field-input {
		// margin-bottom: 0.9em;
		@include rem(margin-bottom, 31px);
	}

	.b-form__rb-group {
		margin-top: 9px;
		padding-bottom: 20px;

		.parsley-errors-list {
			margin-top: 5px;
		}
	}

	.b-form__rb {
		display: flex;
		align-items: center;

		& > * {
			flex: 0 1 auto;
		}

		& + .b-form__rb {
			margin-top: 11px;
		}

		& > input[type="radio"] {
			display: none;

			&:hover,
			&:focus {
				& + label:before {
					background-color: rgba(252, 181, 59, 0.3);
				}
			}

			&:checked + label:before {
				background-color: rgba(252, 181, 59, 1);
			}
		}

		& > label {
			// display: flex;
			// align-items: baseline;
			display: block;
			padding-left: 20px;
			position: relative;

			& > * {
				// flex: 0 1 auto;
			}

			&:before {
				content: '';
				min-width: 13px;
				min-height: 13px;
				border: 1px solid #404041;
				display: inline-block;
				border-radius: 50%;
				margin-right: 10px;
				transition: background-color 0.25s ease;

				position: absolute;
				left: 0;
				top: 3px;
			}
		}

		input[type="text"] {
			margin-left: 6px;
		}
	}

	.b-form__field--upload {
		display: flex;
		margin-top: 12px;
		padding-bottom: 15px;

		.b-form__field-input {
			padding-bottom: 0;
		}

		input {
			display: none;
		}

		.parsley-errors-list li {
			white-space: nowrap;
		}
	}

	.b-form__field--message {
		margin-top: 6px;
	}

	.b-form__field-text--upload {
		margin-top: 0px;
		display: block;
		@include font(600);
		padding-left: 0;
		color: #e42313;
		text-decoration: underline;
		cursor: pointer;
	}

	.b-form__field-text--upload-file {
		overflow: hidden;
		text-overflow: ellipsis;
		padding-left: 32px;
	}

	.b-form__text--upload {
		margin-top: 4px;
	}

	.b-captcha {
		.b-form__field-input {
			margin-top: 2px;
		}

		.b-captcha__image {
			margin-left: 20px;
		}

		.b-form__field-placeholder--smart {
			top: 0.2em;
			left: 0.35em;

			& > span {
				color: $color-error;
			}
		}
	}

	.b-form__text,
	.b-form__field-title {
		span {
			color: $color-error;
		}
	}

	input[type="text"] {
		height: 30px;
	}

	.parsley-errors-list {
		position: relative;
		margin-top: 0;

		& > li {
			margin-top: 0.1em;
			padding-left: 0.15em;

			&:before {
				content: none;
			}
		}
	}

	.b-form__field--policy {
		.parsley-errors-list {
			position: static;
		}

		.b-form__field-input {
			margin-bottom: 2.4em;
		}
	}

	.parsley-errors-list-wrapper-target,
	.parsley-errors-list-wrapper-size {
		.parsley-errors-list {
			& > li {
				position: absolute;
				display: block;
				color: #CE1126;
				@include font-size(14px);
				@include rem(margin-left, 11px);
				top: -10px;
			}
		}
	}

	.requried-text {
		margin: 1em 0;
	}
}

.requried-text {
	span {
		color: $color-error;
	}
	font-weight: 600;
}
