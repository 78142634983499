$bc-link-color: $color-eclipse2;
$bc-link-color-hover: rgba(64,64,64,0.6);
$bc-link-color-active: $color-supernova;
$bc-link-color-visited: $color-eclipse2;

$bc-home-icon-color: $color-eclipse2;
$bc-home-icon-color-hover: rgba(64,64,64,0.6);
$bc-home-icon-color-active: $link-active-color;

$bc-item-color: $color-eclipse2;
$bc-dash-color: $color-cinnabar;

$bc-sub-bg: #cdcdcd;
$bc-sub-link-color: $link-default-color;
$bc-sub-link-color-hover: $link-hover-color;

@if ($contrast) {
	$bc-link-color: $link-default-color;
	$bc-link-color-hover: $link-hover-color;
	$bc-link-color-active: $link-active-color;
	$bc-link-color-visited: $bc-link-color;

	$bc-home-icon-color: #444444;
	$bc-home-icon-color-hover: $link-hover-color;
	$bc-home-icon-color-active: $link-active-color;

	$bc-item-color: black;
	$bc-dash-color: $color-ironside-gray;

	$bc-sub-bg: #cdcdcd;
	$bc-sub-link-color: $link-default-color;
	$bc-sub-link-color-hover: $link-hover-color;
}

.breadcrumbs {
	@extend .no-markers;
	margin-top: 0;
	font-size: 12px;
	line-height: 14px;
	margin-bottom: 20px;

	.reportType--csr & {
		font-family: Arial;
	}

	ul.breadcrumbs-sub {
		display: none;
		position: absolute;
		white-space: nowrap;
		@include rem(padding, 2px 5px);
		color: $bc-sub-link-color;
		background-color: $bc-sub-bg;
		margin-top: 0;
		z-index: 11;

		li.breadcrumbs-sub__item {
			margin-top: 0;
			padding-left: 0;

			& + .breadcrumbs-sub__item {
				@include rem(margin-top, 4px);
			}
		}

		&__link {
			color: $bc-sub-link-color;

			&:visited {
				color: $bc-sub-link-color;

				&:hover {
					color: $bc-sub-link-color-hover;
				}
			}

			&:hover {
				color: $bc-sub-link-color-hover;
				text-decoration: none;
			}
			.reportType--csr & {
				color: $color-night-rider;

				&:hover {
					color: $color-nobel;
					background-color: transparent;
				}

				&:active {
					color: $color-supernova-kco;
					background-color: transparent !important;
				}
			}
		}
	}

	ul.breadcrumbs__list {
		margin-top: 0;
	}

	&__item {
		position: relative;
		display: inline-block;
		color: $bc-item-color;
		vertical-align: middle;

		& + .breadcrumbs__item {
			@include rem(padding-left, 44px);

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 4px;
				height: 4px;
				background-color: $color-cinnabar;
				left: 20px;

				.reportType--csr & {
					background-color: $color-harley-davidson-orange;
				}
			}
		}


		&:hover {
			.breadcrumbs-sub {
				display: block;
			}
		}
	}
	&__item-inner--nolink {
		border-bottom: 3px solid rgba(145,145,145,0.25);

		.reportType--csr & {
			border-color: $color-whisper;
		}
	}

	&__link {
		text-decoration: none;
		font-size: 12px;
		line-height: 14px;

		color: $bc-link-color;
		background: transparent;

		&:visited {
			color: $bc-link-color-visited;
		}

		&:hover,
		&:focus {
			color: $bc-link-color-hover;
			text-decoration: none;
			background: transparent;
		}

		&:active {
			color: $bc-link-color-active;
			background: transparent;
		}

		.reportType--csr & {
			color: $color-night-rider;
			
			&:hover {
				color: $color-nobel;
				background-color: transparent;
			}

			&:active {
				color: $color-supernova-kco;
				background-color: transparent !important;
			}
		}
	}

	// &__link--home {
	// 	position: relative;
	// 	display: block;
	// 	color: $bc-home-icon-color;
	// 	font-size: 0;
	// 	margin-bottom: 2px;
	// 	@include webfont-icon($webfont-icon--home);
	// 	text-decoration: none;

	// 	&:before {
	// 		@include font-size(16px);
	// 	}

	// 	&:hover,
	// 	&:focus {
	// 		color: $bc-home-icon-color-hover;
	// 	}

	// 	&:active {
	// 		color: $bc-home-icon-color-active;
	// 	}
	// }
}

a.breadcrumbs__link {
	&:hover,
	&:focus {
		color: #F57F29;
	}
}