$button-width: 50%;
$button-up-width: 30;
$icon-side-margin: 5px;
$button-side-padding: 10px;

// Стили для случая, когда page-nav зафиксирован при скролле
.b-fixed-area {
	@extend %wrapper;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 10;
	text-align: center;
}

// .b-fixed-area--above-footer {
// 	bottom: $footer-height;
// }

// Стили для случая, когда page-nav находится внизу страницы
.b-now-static-area {
	position: relative;
	width: 100%;
}

.b-pagenav--bottom {
	background: $color-white;
	padding: 5px 0;
	display: none;

	.b-pagenav {
		&__centred {
			@include rem(line-height, $pagenav--link-height);
			color: $pagenav--icon-color;
			position: relative;
			@extend %clearfix;
		}

		&__link-content-wrapper {
			display: flex;
			align-items: center;
			width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		&__text {
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: calc(100% - 30px);
		}

		&__link {
			display: flex;
			align-items: center;
			position: relative;
			@include rem(height, $pagenav--link-height);
			background-color: $pagenav--btn-bg;
			transition: background-color 0.2s ease;
			width: calc(#{$button-width} - #{$button-up-width / 2 * 1px});
			text-decoration: none;
			color: $color-taupe-gray;

			&:before {
				@include font-size(18px);
			}

			.reportType--csr & {
				border-color: rgba($color-nobel,0.8);

				&:visited {
					background-color: $color-white;
				}

				&:hover {
					& .b-icon {
						color: $color-black;
					}
				}

				&:active {
					& .b-icon {
						color: $color-pumpkin-kco;
					}
				}
			}

			.b-icon--prev {
				@include rem(margin-right, $icon-side-margin);
				@include webfont-icon($webfont-icon--i-nav-left);

				&:before {
					display: block;
					@include font-size(8px);
				}
			}
			

			.b-icon--next {
				@include rem(margin-left, $icon-side-margin);
				@include webfont-icon($webfont-icon--i-nav-left);

				&:before {
					display: block;
					@include font-size(8px);
				}
			}

			.b-icon {
				width: 25px;
				height: 25px;
				min-width: 25px;
				min-height: 25px;
				border: 1px solid $color-suva-grey2;
				background-color: $color-white;
				position: relative;

				&:before {
					display: inline-block;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					position: absolute;
					color: $color-suva-grey2;
				}

				&--scrollup {
					&:before {
						transform: rotate(90deg) translate(0, 7px);
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						transform-origin: center;
					}
				}

				&--next {
					&:before {
						transform: translate(-50%, -50%) rotate(180deg);
					}
				}
			}

			&:hover,
			&:focus,
			&.is-touch {
				background-color: $pagenav--btn-bg-hover;
				color: $pagenav--icon-color-hover;

				.b-icon {
					color: $color-black;

					&:before {
						color: $pagenav--icon-color-hover;
					}
				}

				.b-pagenav__title {
					left: 0;
					opacity: 1;
				}

				.b-pagenav__text {
					color: $pagenav--icon-color-hover;
				}
			}

			&:active {
				background-color: $pagenav--btn-bg-active;
				color: $pagenav--icon-color-active;

				.b-icon {
					color: $color-pumpkin;

					&:before {
						color: $pagenav--icon-color-active;
					}
				}

				.b-pagenav__text {
					color: $pagenav--icon-color-active;
				}
			}

		}

		&__scrollup-wrapper {
			@extend %center-position;
			width: $button-up-width * 1px;
			background-color: $pagenav--btn-bg;

			.b-icon--scrollup {
				@include font-size(10px);
				@include webfont-icon($webfont-icon--i-nav-left);
				margin: auto;
				left: 0;
				right: 0;

				&:before {
					display: block;
					@include font-size(8px);
					transform: rotate(90deg);
				}
			}

			.b-pagenav__scrollup {
				@extend .b-pagenav__link;
				text-align: center;
				width: 100%;
				cursor: pointer;

				&.is-touch {
					background-color: $pagenav--btn-bg-hover;

					.b-pagenav__title {
						left: 0;
						opacity: 1;
					}
				}

				.b-pagenav__text--scrollup {
					display: none;
				}
			}
		}

		&__link--prev {
			float: left;
			text-align: left;
			@include rem(padding-left, 16px);
			@include rem(padding-right, $button-side-padding);

			// .b-icon--prev {
			// 	@include webfont-icon($webfont-icon--prev);
			// }
		}

		&__link--next {
			float: right;
			text-align: right;
			@include rem(padding-left, $button-side-padding);
			@include rem(padding-right, 16px);

			.b-icon--next {
				order: 1;
				// @include webfont-icon($webfont-icon--next);
			}

			.b-pagenav__link-content-wrapper {
				justify-content: flex-end;
			}
		}

		&__title {
			display: block;
			position: absolute;
			width: 100%;
			@include rem(bottom, $pagenav--link-height);
			left: -9999px;
			line-height: $base-line-height;
			color: $pagenav--title-color;
			background-color: $pagenav--title-bg;
			@include font-size(13px);
			margin: 0;
			@include rem(padding, 15px);
			opacity: 0;
			transition: opacity 0.2s ease;

			.b-pagenav-help {
				display: block;
			}
		}

		// Стили, применяемые к неактивным кнопкам пагинации
		&__link--disabled {
			// opacity: 0.3;
			pointer-events: none;
			background-color: $pagenav--btn-bg;
			cursor: default;
			border-color: rgba(145,145,145,0.3);

			.b-icon {
				opacity: 0.3;
			}

			&:hover {
				background-color: $pagenav--btn-bg;

				.b-pagenav__title {
					left: -9999px;
					opacity: 0;
				}
			}

			&:active {
				background-color: $pagenav--btn-bg;
			}
			.reportType--csr & {
				border-color: $color-very-light-grey;
			}
		}
		@include breakpoint(xs) {
			&__text {
				display: none;
			}
		}
	}
}

.pagenav--hidden-up {
	.b-pagenav__scrollup-wrapper {
		display: none;
	}

	.b-pagenav__link {
		width: 50%;
	}
}

@if ($responsive) {
	@include breakpoint(sm) {
		.b-pagenav--bottom {
			display: block;
		}
	}
}
