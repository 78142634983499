$excel--btn-color: $color-suva-grey;
$excel--btn-color-hover: $color-black;
$excel--btn-color-active: $color-white;

.button--excel {
	color: $excel--btn-color;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	background-color: transparent;
	@include webfont-icon($webfont-icon--i-xls);
	font-size: 0;
	@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */
	width: 26px;
	height: 26px;
	border: 1px solid $color-suva-grey;
	position: relative;

	.reportType--csr & {
		font-family: Arial;
		color: $color-nobel;
		border-color: $color-nobel;
	}

	&:before {
		display: block;
		font-size: 8px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&:hover {
		color: $excel--btn-color-hover;
		border-color: $excel--btn-color-hover;
	}

	&:active {
		color: $excel--btn-color-active;
		background-color: $color-black;
		border-color: $color-black;
	}

	.browser-ie &:before,
	.browser-edge-17 &:before,
	.browser-edge-18 &:before {
		position: absolute;
	}
}

.button--show-only-in-mobile {
	display: none;
}

@if ($responsive) {
	@include breakpoint(sm) {
		.button--show-only-in-mobile {
			display: initial;
		}

		.figure-buttons .button.button--excel,
		.button--hide-table {
			background: $color-black;
			border: none;
			width: 195px;
			height: 48px;
			@include font(600, FranklinGothic);
			font-size: 16px;
			line-height: 18px;
			padding-left: 20px;
			padding-right: 20px;
			text-transform: uppercase;
			align-items: flex-start;

			color: $color-white;

			@include webfont-icon($webfont-icon--i-download-table);

			.reportType--csr & {
				font-family: Arial;
			}

			&:before {
				font-size: 16px;
				left: auto;
				right: 20px;
			}

			&:active {
				background: $color-supernova;
				border: none;
				color: $color-white;

				.reportType--csr & {
					background: $color-supernova-kco;
				}
			}
		}
	}
}

.download-arrow {
	position: fixed;
	z-index: 999;
	display: none;
	height: 140px;
	width: 120px;
	opacity: 0;
	@include svg(120px, 140px, '/images/icons/download.svg');
	background-repeat: no-repeat;
	background-position-x: 0;
	background-position-y: 0;
}

.download-arrow--ie8 {
	top: 10px;
	left: 20px;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0) progid:DXImageTransform.Microsoft.AlphaImageLoader(src="https://i.imgur.com/aMwoyfN.png", sizingMethod="scale") progid:DXImageTransform.Microsoft.matrix(M11=1, M12=1.2246063538223773e-16, M21=-1.2246063538223773e-16, M22=-1, SizingMethod="auto expand");
}

.download-arrow--ie9 {
	bottom: 50px;
	left: 67%;
}

.download-arrow--chrome {
	bottom: 30px;

	.browser-chrome & {
		left: 20px;
	}

	.browser-yabrowser &,
	.browser-opera & {
		top: 30px;
		right: 20px;
		transform: rotateX(180deg) rotateY(180deg);
	}
}

.download-arrow--safari {
	top: 0px;
	right: 100px;
	transform: rotateX(180deg) rotateY(180deg);
}

.download-arrow--firefox {
	top: 0px;
	right: 65px;
	transform: rotateX(180deg) rotateY(180deg);
}

.button--hide-table {
	margin-top: 10px;
	position: relative;
	@include font-size(14px);
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 13px;
	text-decoration: none;
	cursor: default;

	&:before {
		font-size: 18px;
		position: absolute;
		left: 15px;
		right: auto;
	}

	&:visited {
		background: $color-black;
		color: $color-white;
	}
}

@media (min-width: 799px) {
	.button--hide-table {
		display: none;
	}
}
