.text-capex {
	display: flex;
	flex-direction: row;
	width: 50%;
	justify-content: space-between;
	border-bottom: 1px solid $color-supernova-light;
	padding-bottom: 10px;

	&--red {
		border-color: $color-cinnabar;
	}
}
@media (max-width: 798px) {
	.text-capex {
		width: 100%;
	}
}

@media (max-width: 800px) {
	.l-page__nav-breadcrumbs {
		display: none;
	}
}

.body--operate {
	.scroll {
		overflow-y: hidden;
		overflow-x: auto;

		.wrapper {
			@include breakpoint(md) {
				min-width: 1024px;
			}
		}
	}
}
