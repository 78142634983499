$accordion--controls-height: 40px;
$accordion--button-bg: $color-supernova;
$accordion--button-bg-hover: $color-pumpkin;
$accordion--button-bg-active: $color-cinnabar;
$accordion--button-text-color: $color-suva-grey;
$accordion--button-text-color-hover: $color-suva-grey;
$accordion--button-text-color-active: $color-cinnabar;
$accordion--article-bg: $color-eallery;
$accordion--article-close-hover: orange;
$accordion--control-icon-bg: $color-navy-blue;
$accordion--control-icon-color: $color-white;
$accordion--control-item-bg: $color-celeste;
$accordion--control-item-bg-hover: $color-ironside-gray;
$accordion--control-item-bg-active: $color-mine-shaft;
$accordion--control-text-color: $base-text-color;
$accordion--control-text-color-hover: $color-white;
$accordion--control-text-color-active: $color-white;
$accordion--article-close-bg: $base-text-color;
$accordion--article-close-bg-hover: $color-navy-blue;
$accordion--article-close-bg-active: $color-prussian-blue;
$accordion--button-font-size: 20px;

@if ($contrast) {
	$accordion--button-bg: $color-celeste;
	$accordion--button-bg-hover: $color-ironside-gray;
	$accordion--button-bg-active: $color-mine-shaft;
	$accordion--button-text-color: $base-text-color;
	$accordion--button-text-color-hover: $color-white;
	$accordion--button-text-color-active: $color-white;
	$accordion--article-bg: $color-eallery;
	$accordion--article-close-hover: orange;
	$accordion--control-icon-bg: $color-navy-blue;
	$accordion--control-icon-color: $color-white;
	$accordion--control-item-bg: $color-celeste;
	$accordion--control-item-bg-hover: $color-ironside-gray;
	$accordion--control-item-bg-active: $color-mine-shaft;
	$accordion--control-text-color: $base-text-color;
	$accordion--control-text-color-hover: $color-white;
	$accordion--control-text-color-active: $color-white;
	$accordion--article-close-bg: $base-text-color;
	$accordion--article-close-bg-hover: $color-navy-blue;
	$accordion--article-close-bg-active: $color-prussian-blue;
}

%control-icon {
	content: '';
	display: block;
	position: absolute;
	background-color: $accordion--control-icon-color;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	@include rem(width, 20px);
	@include rem(height, 2px);
}

.b-accordion {
	margin-bottom: 63px;
	&__section {
		@include rem(margin-bottom, 1px);
	}

	&__header {
		border-bottom: 1px solid $color-suva-grey;
	}

	&__button {
		display: block;
		position: relative;
		@include rem(padding, 10px 40px 10px 0px);
		text-decoration: none;
		color: $accordion--button-text-color;
		cursor: pointer;
		transition: all .2s ease-in-out;
		text-transform: uppercase;
		font-size: 18px;
		line-height: 22px;
		@include font(600, 'FranklinGothic');

		.reportType--csr & {
			font-family: Arial;
			color: $color-nobel;
		}

		&:visited {
			color: $accordion--button-text-color;

			&:focus {
				color: $accordion--button-text-color;
			}
		}

		&:hover,
		&:focus {
			color: $accordion--button-text-color-hover;
			background-color: transparent;
			.b-accordion__icon,
			.b-accordion__text::before {
				background-color: $accordion--button-bg-hover;
			}

			&:visited {
				color: $accordion--button-text-color-hover;
				background-color: transparent;
			}

			.reportType--csr & {
				color: $color-nobel;
				.b-accordion__icon,
				.b-accordion__text::before {
					background-color: $color-pumpkin-kco;
				}
			}		
		}

		&.is-expand,
		&:active {
			color: $accordion--button-text-color-active;
			background-color: transparent;

			.b-accordion__icon,
			.b-accordion__text::before {
				background-color: $accordion--button-bg-active;
			}

			&:visited {
				color: $accordion--button-text-color-active;
			}

			.reportType--csr & {
				.b-accordion__icon,
				.b-accordion__text::before {
					background-color: $color-harley-davidson-orange;
				}
			}	
		}

		&.is-expand {
			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}
		}

		// &:before,
		// &:after {
		// 	content: '';
		// 	position: absolute;
		// 	display: block;
		// 	@include rem(width, 3px);
		// 	@include rem(height, 15px);
		// 	background-color: $accordion--button-text-color;
		// 	top: 0;
		// 	bottom: 0;
		// 	margin: auto;
		// 	transition: transform 0.2s ease;
		// 	border-radius: 5px;
		// }

		// &:before {
		// 	@include rem(right, 29px);
		// 	transform: rotate(-45deg);

		// }

		// &:after {
		// 	@include rem(right, 20px);
		// 	transform: rotate(45deg);
		// }
	}

	&__text {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -12px;
			width: 100%;
			height: 3px;
			background-color: $color-supernova;

			.reportType--csr & {
				background-color: $color-supernova-kco;
			}
		}
	}

	&__icon {
		position: absolute;
		right: 0;
		bottom: 0;
		@include webfont-icon($webfont-icon--i-arrow-accordion);
		width: 37px;
		height: 23px;
		background-color: $color-supernova;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&::before {
			font-size: 7px;
			color: $color-white;
		}

		.reportType--csr & {
			background-color: $color-supernova-kco;
		}
		
	}

	&__article {
		position: relative;
		margin-top: 0;
		padding: 21px 36px 7px 0px;
		border-right: 1px solid $color-suva-grey;
		display: none;

		&.is-expand {
			display: block;

			.b-accordion__article-close {
				&:before {
					transform: rotate(45deg);
				}

				&:after {
					transform: rotate(-45deg);
				}
			}
		}

	}

	&__article-close {
		position: absolute;
		display: block;
		@include rem(width, 32px);
		@include rem(height, 32px);
		@include rem(right, 10px);
		background: transparent;
		bottom: 0px;
		cursor: pointer;

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			@include rem(width, 3px);
			@include rem(height, 15px);
			background-color: $accordion--button-text-color;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform 0.2s ease;
			border-radius: 5px;
		}

		&:before {
			@include rem(right, 19px);
			transform: rotate(-45deg);

		}

		&:after {
			@include rem(right, 10px);
			transform: rotate(45deg);
		}

		&:hover,
		&:focus {
			background: transparent;

			&:before {
				background-color: $accordion--article-close-bg-hover;
			}

			&:after {
				background-color: $accordion--article-close-bg-hover;
			}
		}

		&:active {
			background: transparent;

			&:before {
				background-color: $accordion--article-close-bg-active;
			}

			&:after {
				background-color: $accordion--article-close-bg-active;
			}
		}

		&.is-hidden {
			display: none;
		}
	}

	&__control {
		@include rem(padding-top, 10px);
	}

	&__control-item {
		display: inline-block;
		@include rem(height, $accordion--controls-height);
		@include rem(line-height, $accordion--controls-height);
		@include rem(margin-bottom, 10px);
		background-color: $accordion--button-bg;
		color: $accordion--control-text-color;
		padding: 0;
		cursor: pointer;

		&:first-child {
			@include rem(margin-right, 10px);
		}

		&:hover {
			background-color: $accordion--control-item-bg-hover;
			color: $accordion--control-text-color-hover;
		}

		&:active {
			background-color: $accordion--control-item-bg-active;
			color: $accordion--control-text-color-active;
		}
	}

	&__control-icon {
		display: block;
		position: relative;
		height: 100%;
		@include rem(width, $accordion--controls-height);
		float: left;
		background-color: $accordion--control-icon-bg;
	}

	&__control-text {
		@include rem(padding-left, 15px);
		@include rem(padding-right, 15px);
	}

	&__control-item--expand {
		.b-accordion__control-icon {
			&:before,
			&:after {
				@extend %control-icon;
			}

			&:before {
				transform: rotate(0deg);
			}

			&:after {
				transform: rotate(90deg);
			}
		}
	}

	&__control-item--collapse {
		.b-accordion__control-icon:before {
			@extend %control-icon;
		}
	}
}
