$tabs--button-bg: $color-supernova;
$tabs--button-bg-active: $color-cinnabar;
$tabs--button-bg-hover: $color-pumpkin;
$tabs--button-text-color: $color-suva-grey;
$tabs--button-text-color-hover: $color-suva-grey;
$tabs--body-bg: $color-suva-grey;

.b-tabs {
	margin-bottom: 23px;

	&__nav {
		border-bottom: 1px solid $color-suva-grey;
		align-items: flex-end;
	}

	&__button {
		@extend %disable-visited;
		@extend %collapse--top;
		display: flex;
		flex-grow: 1;
		flex-shrink: 1;
		justify-content: center;
		align-items: center;
		padding: 5px 5px 10px;
		position: relative;
		text-decoration: none;
		color: $tabs--button-text-color;
		text-align: center;
		height: 100%;
		@include font(600, 'FranklinGothic');
		text-transform: uppercase;
		font-size: 18px;
		line-height: 20px;

		transition: all .2s ease-in-out;
		
		.reportType--csr & {
			font-family: Arial;
		}

		&:visited {
			color: $tabs--button-text-color;
		}

		&:focus {
			color: $tabs--button-text-color;
			background-color: transparent;
			.b-tabs__nav-text::before {
				background-color: $tabs--button-bg;
			}

			&:visited {
				color: $tabs--button-text-color;
				background-color: $tabs--button-bg;
			}
		}

		&:hover {
			color: $tabs--button-text-color-hover;
			background-color: transparent;
			.b-tabs__nav-text::before {
				background-color: $tabs--button-bg-hover;
			}

			&:visited {
				color: $tabs--button-text-color-hover;
				background-color: $tabs--button-bg-hover;
			}

					
			.reportType--csr & {
				color: $color-nobel;

				.b-tabs__nav-text::before {
					background-color: $color-pumpkin-kco;
				}
			}
		}

		&.is-expand,
		&.is-expand:focus {
			color: $tabs--button-text-color-hover;
			background-color: transparent;
			.b-tabs__nav-text::before {
				background-color: $tabs--button-bg-active;

				.reportType--csr & {
					background-color: $color-harley-davidson-orange;
				}
			}

			&:visited {
				color: $tabs--button-text-color-hover;
				background-color: $tabs--button-bg-active;

			}
		}
	}

	&__nav-text {
		position: relative;
		width: auto;
		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -10px;
			width: 100%;
			height: 3px;
			background-color: $color-supernova;

			.reportType--csr & {
				background-color: transparent;
			}
		}

		.reportType--csr & {
			color: $color-nobel;
		}
	}

	&__nav-item {
		// border-left: 1px solid $color-white;

		// &:first-child {
		// 	border-left: none;
		// }
	}

	&__body {
		@extend %collapse--top;
		width: 100%;
	}

	&__article {
		@extend %collapse--top;
		position: absolute;
		left: -99999px;
		top: -99999px;
		padding: 21px 39px 5px 0px;
		border-right: 1px solid $tabs--body-bg;

		&.is-expand {
			position: relative;
			left: 0;
			top: 0;
		}

		.reportType--csr & {
			font-family: Arial;
		}
	}

	&--bm & {
		&__nav {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
		}

		&__article {
			padding-top: 33px;

			&:last-child {
				&::before,
				&::after {
					opacity: 0;
					transition: opacity .3s ease;
				}
				&.is-expand {
					position: relative;
					&::after {
						content: '';
						position: absolute;
						right: -4px;
						top: -4px;
						width: 4px;
						height: calc(100% + 4px);
						background-color: $color-supernova;
						opacity: 1;
					}

					&::before {
						content: '';
						position: absolute;
						left: 0;
						bottom: 0;
						height: 4px;
						width: 100%;
						background-color: $color-supernova;
						opacity: 1;
					}
				}
			}
		}

		&__button {
			padding-left: 0;
			padding-right: 0;
			@include font(500, 'FranklinGothic');

			.reportType--csr & {
				font-family: Arial;
			}

			&:hover {
				color: $color-black;
			}

			&.is-expand,
			&.is-expand:focus {
				color: $color-pumpkin;
				.b-tabs__nav-text {
					&::before {
						background-color: $color-pumpkin;
					}
				}
			}
		}

		&__nav-text {
			&::before {
				background-color: $color-pumpkin;
			}
		}

		&__nav-item {
			position: relative;
			padding-right: 46px;

			@include webfont-icon($webfont-icon--next, after);

			&::after {
				position: absolute;
				right: 10px;
				top: 50%;
				font-size: 25px;
				color: $color-suva-grey2;
				margin-top: -19px;
			}

			&:last-child {
				padding-right: 0;
				&::after {
					display: none;
				}

				.b-tabs__button {
					.b-tabs__nav-text {
						&::before {
							background-color: $color-supernova;
						}
					}

					&.is-expand {
						color: $color-supernova;
						.b-tabs__nav-text {
							&::before {
								background-color: $color-supernova;
							}
						}
					}
				}
			}
		}

		@include breakpoint(md) {
			&__nav-item {
				margin-bottom: 10px;
			}
		}
		@include breakpoint(xs) {
			&__nav {
				display: block;
			}

			&__nav-item {
				padding-right: 0;

				&::after {
					display: none;
				}
			}

			&__article {
				&:last-child {

					&.is-expand {
						&::before,
						&::after {
							opacity: 0;
						}
					}
				}
			}
		}
	}
}

.body--community {
	.b-tabs__nav {
				&-text {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			min-width: 250px;
			min-height: 50px;
			@media (max-width: 1480px) {
				min-width: 220px;
				min-height: 40px;
				@include font-size(14px);
			}

			@media (max-width: 1360px) {
				min-width: 200px;
				min-height: 35px;
				@include font-size(14px);
			}


			@media (max-width: 1280px) {
				min-width: 180px;
				min-height: 35px;
				@include font-size(12px);
			}

			@media (max-width: 1190px) {
				min-width: 150px;
				min-height: 35px;
				@include font-size(12px);
			}

			@include breakpoint(md) {
				min-width: 200px;
				max-width: 200px;;
			}
			@include font-size(16px);
			&:before {
				transition: all ease 0.3s;
			}
		}

		.tab-icon {
			display: inline-flex;
			margin-right: 10px;
		}
	}

	.browser-ie {
		.b-tabs__nav-text {
			@include font-size(15px);
		}

		.tab-icon {
			display: inline-block;
			margin-right: 10px !important;
		}
	}
}
