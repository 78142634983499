.bm {

    &__list-top {
        margin-bottom: 62px !important;
        margin-left: 0;
    }

    &__item-top {
        border-left: 10px solid $color-supernova;
        padding-left: 25px;
        margin-top: 0 !important;

        &::before {
            display: none;
        }
    }

    &__lead {
        padding-right: 30px;
    }

    &__lead-title {
        font-size: 30px;
        line-height: 30px;
        @include font(900, 'Humanist');
        color: $color-black;
        margin-bottom: 11px;

        &--red-line {
            margin-bottom: 24px;
            position: relative;
            padding-bottom: 13px;

            @media (max-width: 800px) {
                @include font-size(26px);
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                height: 10px;
                width: 80px;
                background-color: $color-cinnabar;

            }
        }
    }

    &__lead {
        p {
            font-size: 19px;
            line-height: 23px;
            @include font(500, 'FranklinGothic');
            color: $color-black;
            margin: 0;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;

        &--basis {
            margin-bottom: 48px !important;
        }
    }

    &__item {
        &::before {
            display: none;
        }
        margin-top: 0 !important;

        &--basis {
            display: flex;
        }

        &--value {
            margin-bottom: 25px;
        }
    }

    &__basis {
        display: flex;
    }

    &__basis-text {
        font-size: 32px;
        line-height: 32px;
        color: $color-black;
        @include font(900, 'Humanist');
    }

    &__icon {
        flex: 1 0 auto;
        margin-right: 23px;
        width: 59px;
        height: 60px;
        max-width: 59px;

        &--second {
            width: 37px;
            height: 66px;
        }

        div {
            height: 100%;
            padding: 0 !important;
        }

        &--value {
            width: 33px;
            height: 33px;
            margin-right: 16px;
            flex: 0 0 auto;
        }
    }

    &__inset {
        &--strategic {
            @include font-size(20px);
            line-height: 24px;
            color: $color-black;
            @include font(500, 'FranklinGothic');
            border-top: 10px solid $color-cinnabar;
            padding-top: 10px;

            @media (max-width: 800px) {
                @include font-size(16px);
            }
        }
    }

    .lead {
        color: $color-eclipse2;
        margin-bottom: 30px;
    }

    &__value-title {
        display: flex;
        font-size: 20px;
        line-height: 20px;
        color: $color-black;
        @include font(500, 'FranklinGothic');
        margin-bottom: 10px;
        align-items: center;

        @media (max-width: 800px) {
            @include font-size(18px);
        }
    }

    @include breakpoint(md) {
        &__item,
        &__item-top {
            margin-bottom: 30px !important;
        }
    }

    @include breakpoint(xs) {
        &__item-top {
            margin-bottom: 30px;
        }

        &__item {
            margin-bottom: 30px !important;
        }
    }
}
