.b-pagenav--buttons {
	display: flex;
	flex-direction: column;
	height: auto;
	padding-top: 13px;

	.l-page__nav-controls.is-fixed & {
		top: 0;
		position: fixed;
	}

	.b-pagenav {
		&__link {
			position: relative;
			display: block;
			@include rem(width, $pagenav--link-width);
			@include rem(height, $pagenav--link-height);
			background-color: $pagenav--btn-bg;
			transition: background-color 0.2s ease;
			text-decoration: none;
			border: 1px solid $color-suva-grey2;

			&:hover,
			&.is-touch {
				background-color: $pagenav--btn-bg-hover;
				color: $pagenav--icon-color-hover;

				.b-pagenav__title {
					right: 100%;
					opacity: 1;
					margin-right: 1px;
				}
			}

			&:active {
				color: $pagenav--icon-color-active;
				background-color: $pagenav--btn-bg-active;
			}

			// &--prev {
			// 	right: 0;
			// 	top: 0;
			// 	position: absolute;
			// }

			&--next {
				margin: 10px 0;
				// position: absolute;
				// right: 0;
				// top: 35px;
			}

			.reportType--csr & {
				border-color: rgba($color-nobel,0.8);

				&:visited {
					background-color: $color-white;
				}

				&:hover {
					& .b-icon {
						color: $color-black;
					}
				}

				&:active {
					& .b-icon {
						color: $color-pumpkin-kco;
					}
				}
			}
		}

		&__link--hide-fixed {
			opacity: 0;
		}

		&__link--hide {
			display: none;
		}

		&__link--disabled,
		&__scrollup--disabled {

			pointer-events: none;
			background-color: $pagenav--btn-bg;
			cursor: default;
			border-color: rgba(145,145,145,0.3);

			.b-icon {
				opacity: 0.3;
			}

			&:hover {
				background-color: $pagenav--btn-bg;

				.b-pagenav__title {
					left: -9999px;
					opacity: 0;
				}
			}

			.reportType--csr & {
				border-color: $color-very-light-grey;
			}
		}

		&__scrollup {
			@extend .b-pagenav__link;
			cursor: pointer;
			order: 1;
			// position: absolute;
			// right: 0;
			// top: 70px;
		}

		&__title {
			display: block;
			position: absolute;
			right: 9999px;
			// @include rem(min-width, $pagenav--title-width);
			@include rem(top, $pagenav--link-height);
			padding: 0 5px;
			color: $pagenav--title-color;
			background-color: $pagenav--title-bg;
			opacity: 0;
			transition: opacity 0.2s ease;
			z-index: 10;

			white-space: nowrap;

			top: -1px;
			font-size: 16px;
			line-height: 25px;
			// height: 25px;

			.b-pagenav-help {
				// display: block;
				display: none;
			}

			.reportType--csr & {
				background-color: $color-whisper2;
			}
		}
	}

	.b-icon {
		position: absolute;
		display: inline-block;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		font-size: 0;

		&:before {
			display: block;
		}

		.reportType--csr & {
			color: rgba($color-nobel,0.8);
		}
	}

	.b-icon--scrollup {
		display: block;
		text-align: center;
		@include webfont-icon($webfont-icon--i-nav-left);
		height: 8px;

		&:before {
			display: block;
			@include font-size(8px);
			transform: rotate(90deg);
		}
	}

	.b-icon--prev {
		@include webfont-icon($webfont-icon--i-nav-left);
		height: 8px;


		&:before {
			display: block;
			@include font-size(8px);
		}
	}

	.b-icon--next {
		@include webfont-icon($webfont-icon--i-nav-left);
		height: 8px;

		&:before {
			display: block;
			@include font-size(8px);
			transform: rotate(180deg);
		}
	}
}
