.l-header-preview {
	color: $color-black;
	@include font(900, Humanist);
	position: relative;
	width: 100%;
	// z-index: 2;
	padding-top: 56px;
	// border-bottom: 1px solid $color-black;
	padding-bottom: 32px;

	.reportType--csr & {
		font-family: Arial;
	}

	&:after {
		content: "";
		background: $color-black;
		display: block;
		width: 100vw;
		height: 1px;
		position: absolute;
		left: 0;
		bottom: 0px;
		width: calc(100% + (100vw - 100%)/2);
	}

	&__header {
		font-size: 90px;
		line-height: 90px;
		position: relative;
		padding-bottom: 50px;
		margin-bottom: 33px;
		margin-left: -0.05em;
		// z-index: 3;

		.reportType--csr & {
			font-size: 70px;
			line-height: 70px;
		}

		.reportType--csr--ru.hse-section--ru & {
			@media (min-width: 1441px) {
				padding-right: 22%
			}
		}

		&:after {
			content: "";
			display: block;
			width: 100px;
			height: 10px;
			background: $color-cinnabar;
			position: absolute;
			left: 0;
			bottom: 0;
			transform: translateX(-50%);
		}
	}
	&__description {
		font-size: 50px;
		line-height: 50px;
	}
	&__place {
		font-size: 12px;
		line-height: 12px;
		position: absolute;
		right: 50%;
		top: auto;
		bottom: 140px;
		margin-right: -46vw;
	}
	&__name {
		@include font(600, FranklinGothic);
		margin: 0;

		.reportType--csr & {
			font-family: Arial;
		}
	}
	&__city {
		@include font(400, FranklinGothicBook);
		
		.reportType--csr & {
			font-family: Arial;
		}
	}
	&__photo-wrapper {
		position: absolute;
		right: 50%;
		// top: 0;
		margin-right: -50vw;
		top: auto;
		bottom: 0;
		overflow: hidden;
		width: 50%;
		z-index: 2;

		.reportType--csr & {
			width: 47%;

			@media (max-width: 1440px) {
				display: none;
			}
		}

		picture,
		img {
			width: 100%;
			margin-bottom: 0;
		}

		* {
			display: block;
		}
	}

	@if ($responsive) {

		@include breakpoint(lg) {
			padding-top: 46px;

			&__header {
				font-size: 80px;
				line-height: 80px;
				&:after {
					width: 100px;
					height: 10px;
				}

				.reportType--csr & {
					font-size: 50px;
					line-height: 50px;
				}
			}
			&__description {
				font-size: 40px;
				line-height: 40px;
			}
			&__place {
				font-size: 12px;
				line-height: 12px;
				margin-right: -47.5vw;
			}
			&__photo-wrapper {
				position: absolute;
				right: 50%;
				margin-right: -50vw;
				top: -95px;
				bottom: 0;
				width: 540px;

				.reportType--csr & {
					width: 39%;
					top: -110px;
				}
			}

			&__photo-wrapper * {
				display: block;
				max-width: 100%;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: left;
			}
		}
		@media (max-width: 1380px) {
			&__place {
				margin-right: -41.5vw;
				top: 60px;
			}
			&__photo-wrapper {
				right: 50%;
				margin-right: -50vw;
				top: 0;
				bottom: 0;
				width: 430px;
				height: 100%;
			}
			&__photo-wrapper {
				display: none;
			}
			&__place {
				display: none;
			}
		}
		@include breakpoint(md) {
			padding-top: 34px;
			padding-bottom: 19px;

			&__header {
				font-size: 55px;
				line-height: 55px;
				&:after {
					width: 100px;
					height: 10px;
				}
			}
			&__description {
				font-size: 36px;
				line-height: 36px;
			}
			&__photo-wrapper {
				display: none;
			}
			&__place {
				display: none;
			}
		}
		@include breakpoint(sm) {

			margin: 0 -32px;
			padding-left: 32px;
			padding-right: 32px;
			overflow: hidden;
			width: auto;

			&__header {
				padding-bottom: 42px;
				margin-bottom: 20px;
			}
			&__photo-wrapper {
				display: none;
			}
			&__place {
				display: none;
			}
			// &__photo-wrapper {
			//     right: -94px;
			//     margin: 0;
			// }
			// &__photo-wrapper * {
			//     object-fit: contain;
			// }
			// &__place {
			//     margin-right: 0;
			//     top: 32px;
			//     right: 18px;
			// }
		}
		@include breakpoint(xs) {
			padding-top: 18px;
			padding-bottom: 12px;


			margin: 0 -16px;
			padding-left: 16px;
			padding-right: 16px;
			overflow: hidden;
			width: auto;

			&__photo-wrapper {
				display: none;
			}
			&__place {
				display: none;
			}
			&__header {
				font-size: 30px;
				line-height: 30px;
				padding-bottom: 21px;
				margin-bottom: 11px;
				&:after {
					height: 4px;
					width: 88px;
					left: 11px;
				}
			.reportType--csr & {
					font-size: 30px;
					line-height: 30px;
				}
			}
			&__description {
				font-size: 22px;
				line-height: 22px;
			}
		}
	}
}
