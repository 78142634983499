/* Links */

/**
 * Remove the gray background color from active links in IE 10.
 */

a {
	color: $link-default-color;
	background-color: transparent;
	transition: color 0.2s ease, background-color 0.2s ease;

	.reportType--csr & {
		font-family: Arial;

		&:visited {
			color: $color-nobel;
			background-color: $color-nobel;
			text-decoration: none;
		}
	}

	&:visited {
		color: #b2b2b2;
		background-color: #b2b2b2;
		text-decoration: none;
	}

	&:hover,
	&:visited:hover {
		background-color: $color-black;
		color: $link-hover-color;
		text-decoration: none;
	}
	// &:hover,
	// &:focus,
	// &:visited:hover,
	// &:visited:focus {
	// 	background-color: $color-black;
	// 	color: $link-hover-color;
	// 	text-decoration: none;
	// }

	&:active,
	&:visited:active {
		background-color: $color-supernova;
		color: $link-active-color;
		text-decoration: none;

		.reportType--csr & {
			background-color: $color-supernova-kco;
		}
	}

	&:active,
	&:hover {
		outline: 0;
	}

	&.btn {
		.reportType--csr & {
			font-family: Arial;
		}

		&:visited {
			color: $btn--color;
		}

		&:hover,
		&:focus,
		&:visited:hover,
		&:visited:focus {
			color: $btn--color-hover;
		}

		&:active,
		&:visited:active {
			color: $btn--color-active;

			.reportType--csr & {
				background-color: $color-supernova-kco;
			}
		}
	}
}

[data-anchor-link] {
	cursor: pointer;
}

.link--external {
	@include webfont-icon($webfont-icon--i-link-external);

	&:before {
		// display: inline-block;
		// @include font-size(14px);
		// margin-right: 0.2em;
		// vertical-align: middle;

		display: inline;
		font-size: 9px;
		margin: 0;
	}

	.browser-ie .aside &:before {
		// display: inline-block;
		white-space: nowrap;
	}
}

.link--phone {
	white-space: nowrap;
}

.b-link-icon {
	display: flex;
	//flex: 0 1 auto;
	align-items: center;

	&__icon {
		margin-right: 5px;
		flex-basis: 100%;
		max-width: 25px;
		min-width: 20px;
		flex: 1 0 100%;
	}

	&__text {
		flex-shrink: 1;
	}
}

//иконка слева
.b-link-icon--left {
	.b-link-icon__icon {
		img {
			display: block;
		}
	}
}

//иконка справа
.b-link-icon--right {
	.b-link-icon__icon {
		margin-left: 5px;
		margin-right: auto;

		img {
			display: block;
		}
	}
}

//размеры иконок, при необходимости - добавлять модификаторы - xs, xm, xl

//по умолчанию без модификации
.b-link-icon--size-s {
}

//средний размер
.b-link-icon--size-m {
	.b-link-icon__icon {
		flex-basis: 100%;
		max-width: 30px;
	}
}

//большой размер
.b-link-icon--size-l {
	.b-link-icon__icon {
		flex-basis: 100%;
		max-width: 40px;
	}
}

.b-link-icon--size-free {
	.b-link-icon__icon {
		flex-basis: auto;
		max-width: initial;
	}
}
