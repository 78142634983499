.graphic {
	margin-bottom: 51px;

	&__img {
		@extend %collapse--top;

		#colorbox & {
			width: 100%;
		}
	}

	figcaption {
		font-size: 20px;
		line-height: 24px;
		@include font(900, 'Humanist');
		color: $color-black;
		margin-bottom: $grid-gutter-width;

		.caption__units {
			font-weight: normal;
			color: $color-pumpkin;
			@include font(400, 'FranklinGothicBook');

			.reportType--csr & {
				font-family: Arial;
				color: $color-pumpkin-kco;
			}
		}

		.reportType--csr & {
			font-family: Arial;
		}
	}

	// (fix-scroll)
	// @include breakpoint(md) {
	// 	overflow-y: auto;
	// }
}

.graphic__tools {
	@extend %clearfix;
	@extend %collapse--top;
	position: relative;
	border-top: 1px solid;

	.button + .button {
		margin-right: 1px;
	}
}
