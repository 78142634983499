$pagenav--link-width: 25px;
$pagenav--link-height: 25px;

$pagenav--icon-width: $pagenav--link-width / 1.5;
$pagenav--icon-height: $pagenav--link-width - 4;
$pagenav--icon-color: $color-suva-grey2;
$pagenav--icon-color-hover: $color-black;
$pagenav--icon-color-active: $color-pumpkin;

$pagenav--btn-bg: $color-white;
$pagenav--btn-bg-hover: $color-white;
$pagenav--btn-bg-active: $color-white;

$pagenav--title-width: 250px;
$pagenav--title-color: $color-black;
// $pagenav--title-bg: rgba(145,145,145,0.15);
$pagenav--title-bg: $color-eallery;
$pagenav--title-bg-active: rgba(145,145,145,0.15);

@if ($contrast) {
	$pagenav--icon-color: $color-celeste;
	$pagenav--icon-color-hover: $color-black;
	$pagenav--icon-color-active: $color-carrot-orange;

	$pagenav--btn-bg: $color-eallery;
	$pagenav--btn-bg-hover: $color-celeste;
	$pagenav--btn-bg-active: $color-dark-gray;

	$pagenav--title-color: $color-white;
	$pagenav--title-bg: $color-dark-gray;
	$pagenav--title-bg-active: $color-dark-gray;
}
