header.main-main-page {
	padding-bottom: 0;
}

article.main-main-page {
	padding-bottom: 0;
}

main.main-main-page {
	padding-left: 0;
	padding-right: 0;
}

.main {
	display: flex;
	flex-wrap: wrap;
	position: relative;

	@media (min-width: 1024px) and (min-height: 620px) {
		align-content: center;
		align-items: flex-start;
		justify-content: center;
		height: calc(100vh - 188px);
		max-height: 940px;
		min-height: 460px;
		margin: 0 8px;
	}

	&.preloader {
		.main__item {
			opacity: 0;
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		width: 50%;
		height: calc((100vh - 58px) / 5);
		padding: 16px;
		box-sizing: border-box;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 0 0;
		line-height: normal;
		position: relative;
		@include font(900, Humanist);
		font-size: 16px;
		opacity: 1;

		img {
			padding: 4px 0;
			width: 20px;
			margin: 0;
			min-height: auto;
		}

		&.__line span:after {
			width: 46px;
			height: 5px;
			background-color: #EE2D24;
			content: "";
			display: block;
			margin-top: 4px;
		}

		&.__orange {
			background-color: #F57F29;
		}

		&.__yellow {
			background-color: #FCB53B;
		}

		&.__red {
			background-color: #EE2D24;
		}

		&.__item-11 {
			display: none;
		}

		.main__item-link {
			width: 194px;
			height: 38px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #ffffff;
			background: #EE2D24;
			font-size: 14px;
			text-transform: uppercase;
			@include font(600, Franklin Gothic Demi);
			position: absolute;
			bottom: 33%;
			left: 34px;
			z-index: 1;
			visibility: hidden;
			opacity: 0;
			transition: .3s;

			@include breakpoint(sm) {
				display: none;
			}
		}

		&__mobile-link {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			// избежать перекртыия панели инструментов
			// z-index: 100;
			z-index: 1;

			&:hover {
				background-color: transparent;
			}

			@media (min-width: 1024px) and (min-height: 620px) {
				display: none;
			}
		}

		@media (min-width: 1024px) and (min-height: 620px) {
			width: inherit;
			height: inherit;
			flex-direction: column;
			justify-content: flex-start;
			margin: 8px;
			position: relative;
			transition: transform .3s;
			cursor: pointer;
			z-index: 1;

			&.__as-fe {
				align-self: flex-end;
			}

			&.__item-1 {
				width: 23%;
				max-width: 312px;
				height: 18%;
				max-height: 154px;
				position: absolute;
				margin: 0;
				top: 3%;
				left: 69%;
				font-size: 15px;
				@include font(700, Humanist);
			}

			&.__item-11 {
				display: block;
				width: 18%;
				max-width: 312px;
				height: 18%;
				max-height: 154px;
				position: absolute;
				margin: 0;
				top: 3%;
				left: 5%;
				font-size: 15px;
				@include font(700, Humanist);
			}

			&.__item-2 {
				width: 38%;
				max-width: 680px;
				height: 46%;
				max-height: 444px;

				span {
					font-size: 33px;
					position: absolute;
					left: -16px;
					top: 16px;

					&:after {
						width: 80px;
					}
				}
			}

			&.__item-3 {
				width: 24%;
				max-width: 396px;
				height: 23%;
				max-height: 213px;
				order: -1;

				span {
					font-size: 25px;
					position: absolute;
					top: -16px;
					left: 16px;

					&:after {
						width: 73px;
						height: 7px;
					}
				}

				a {
					width: 150px;
					height: 33px;
				}
			}

			&.__item-4 {
				width: 19%;
				max-width: 312px;
				height: 14%;
				max-height: 131px;
				position: absolute;
				left: 0;
				top: 50%;
				z-index: 0;
				justify-content: flex-end;
				font-size: 12px;
				@include font(700, Humanist);

				img {
					margin-bottom: 12px;
				}
			}

			&.__item-5 {
				width: 17%;
				max-width: 260px;
				height: 26%;
				max-height: 227px;
				position: absolute;
				right: 0;
				top: 35%;
				z-index: 0;
				font-size: 12px;
				@include font(700, Humanist);
				align-items: flex-end;

				img {
					margin: 0 2px 16px 0;
				}
			}

			&.__item-6 {
				width: 20%;
				max-width: 326px;
				height: 28%;
				max-height: 276px;
				margin-right: 10%;

				span {
					font-size: 15px;

					&:after {
						width: 45px;
						height: 7px;
					}
				}

				a {
					width: 150px;
					height: 33px;
					bottom: 12%;
				}
			}

			&.__item-7 {
				width: 16%;
				max-width: 260px;
				height: 22%;
				max-height: 206px;
				margin-left: 5%;

				span {
					position: absolute;
					left: -40px;
					top: 50%;
					font-size: 15px;

					&:after {
						width: 35px;
						height: 5px;
					}
				}

				a {
					width: 130px;
					height: 30px;
					top: 12%;
					left: inherit;
					right: 32px;
				}
			}

			&.__item-8 {
				width: 21%;
				max-width: 312px;
				height: 19%;
				max-height: 154px;
				position: absolute;
				bottom: 4%;
				left: 41%;
				z-index: 0;
				justify-content: flex-end;
				font-size: 12px;
				@include font(700, Humanist);

				img {
					margin-bottom: 8px;
				}
			}

			&.__item-9 {
				width: 24%;
				max-width: 396px;
				height: 22%;
				max-height: 213px;

				span {
					font-size: 15px;
					position: absolute;
					bottom: -16px;
					left: 16px;

					&:after {
						width: 37px;
						height: 5px;
					}
				}

				a {
					width: 130px;
					height: 30px;
					top: 50%;
					transform: translateY(-50%);
					left: inherit;
					right: 32px;
				}
			}

			&.__item-10 {
				width: 40%;
				max-width: 684px;
				height: 36%;
				max-height: 660px;

				span {
					font-size: 15px;

					&:after {
						width: 36px;
						height: 5px;
					}
				}
			}

			&:hover {
				z-index: 2;
				transform: scale(1.15);

				.main__item-link {
					visibility: visible;
					opacity: 1;
				}
			}
		}

		@media (min-width: 1440px) {
			margin: 17px;

			&.__item-2 {
				span {
					font-size: 50px;
					left: -33px;
				}
			}

			&.__item-3 {
				span {
					font-size: 40px;
					top: -25px;
					left: 44px;
				}
			}

			&.__item-4,
			&.__item-5,
			&.__item-8 {
				font-size: 18px;
			}

			&.__item-1,
			&.__item-6,
			&.__item-7,
			&.__item-9,
			&.__item-10,
			&.__item-11 {
				font-size: 24px;

				span {
					font-size: 24px;
				}
			}

			&.__item-1 {
				top: 5%;
			}

			&.__item-5 {
				align-items: flex-start;
				padding-left: 130px;
			}

			&.__item-7 {
				margin-left: 6%;

				span {
					left: -70px;
				}
			}

			&.__item-8 {
				bottom: 3%;
			}

			&.__item-9 {
				span {
					bottom: -20px;
				}
			}

			&.__item-10 {
				span {
					position: relative;
					top: 16px;
					left: 16px;
				}
			}
		}
	}
}

.detail-popup {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	background-color: #ffffff;
	z-index: 100;
	padding: 20px 0 40px;
	line-height: normal;
	box-sizing: border-box;
	overflow: hidden;
	transition: .5s;

	&.__popup-visible {
		opacity: 1;
	}

	&__cover {
		height: 100%;
		width: 95%;
		padding-left: 88px;
		margin: 0 auto;
		position: relative;
		display: flex;
		align-items: center;
		padding-right: 234px;
		box-sizing: border-box;
	}

	&__close {
		height: 20px;
		width: 20px;
		position: absolute;
		left: 0;
		top: 16px;
		padding: 0;
		border: 0;
		background-color: transparent;
		z-index: 100;
		cursor: pointer;
	}

	&__nav {
		position: absolute;
		left: 88px;
		top: 50%;
		transform: translateY(-50%);
		width: 22px;
		height: 574px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		z-index: 11;

		&-item {
			padding: 0;
			border: 0;
			background-color: transparent;
			font-size: 0;
			cursor: pointer;

			&.__prev {
				transform: rotate(-90deg);
			}

			&.__next {
				transform: rotate(90deg);
			}
		}
	}

	&__indication {
		position: absolute;
		bottom: -19px;
		visibility: hidden;
		justify-content: space-between;
		width: 140px;
		z-index: 10;
		left: 50%;
		transform: translateX(-50%);

		&-item {
			width: 6px;
			height: 6px;
			border-radius: 100%;
			background-color: #000000;

			&.__active {
				background-color: #ee2d24;
			}
		}
	}

	&__list {
		@include font(700, Humanist);;
		font-size: 18px;
		width: 184px;
		position: absolute;
		right: 0;
		top: 50%;
		z-index: 1;
		transform: translateY(-50%);
		max-height: 100%;
		overflow-y: auto;

		&-item {
			padding: 27px 14px;
			border-bottom: 1px solid rgba(145, 145, 145, .5);
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
			position: relative;

			&:before {
				content: '';
				width: 4px;
				height: 42px;
				opacity: 0;
				display: block;
				background-color: #F57F29;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}

			&:after {
				content: url("/images/charts/arrow-next.svg");
				display: block;
				margin-right: -8px;
			}

			&:hover,
			&.__active {
				color: #F57F29;

				&:before {
					opacity: 1;
				}
			}

			&.__active {
				color: #EE2D24;

				&:before {
					background-color: #EE2D24;
				}
			}
		}
	}

	&__wrap {
		height: 893px;
		max-height: 100%;
		width: 100%;
		position: relative;
	}

	&__item {
		display: none;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		background-position: center right;
		background-repeat: no-repeat;
		position: relative;
		opacity: 0;
		transition: .4s;

		&.__animation {
			opacity: 1;
			z-index: 10;
		}

		&-title {
			@include font(900, Humanist);
			font-size: 90px;
			margin-bottom: 37px;
			position: relative;
			z-index: 1;
		}

		&-description {
			@include font(900, Humanist);;
			font-size: 18px;
			line-height: 120%;
			position: relative;
			z-index: 1;
		}

		&-link {
			width: 194px;
			height: 38px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #ffffff;
			background: #EE2D24;
			font-size: 16px;
			text-transform: uppercase;
			@include font(400, Humanist);
			position: relative;
			z-index: 1;

			&:hover {
				background-color: #F57F29;
			}
		}

		&-img {
			width: 80%;
			right: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			height: 100%;
			background-size: cover;
			display: flex;
			flex-wrap: wrap;

			&:after {
				content: "";
				width: calc(100% + 4px);
				height: calc(100% + 4px);
				display: block;
				position: absolute;
				left: -2px;
				top: -2px;
				border: 4px solid white;
				box-sizing: border-box;
			}
		}

		&-overflow {
			position: relative;
			overflow: hidden;
		}

		&-window {
			position: absolute;
			will-change: left, right, top, bottom;
			transition: .3s;
			transition-delay: 1s;
			box-shadow: 0 0 0 1000px rgba(255, 255, 255, 1);
		}

		&.__item-1 {
			a {
				margin-top: 54px;
			}

			.detail-popup__item-img {
				background-image: url("/images/main/Digital.jpg");
				width: 90%;
				flex-direction: column;
			}

			.detail-popup__item-overflow {
				&.__over-1 {
					width: 65%;
					height: 30%;
					top: 1px;

					span {
						width: 77%;
						height: 69%;
						right: 25px;
						bottom: 25px;
					}
				}

				&.__over-2 {
					width: 65%;
					height: 70%;

					span {
						width: calc(100% - 25px);
						height: calc(100% - 25px);
						top: 25px;
						right: 25px;
					}
				}

				&.__over-3 {
					height: 100%;
					width: 35%;
					right: 1px;

					span {
						width: calc(100% - 25px);
						height: 91%;
						left: 25px;
						top: 0;
					}
				}
			}

			&.__animation {
				.__over-1 {
					span {
						right: 0;
						bottom: 0;
					}
				}

				.__over-2 {
					span {
						top: 0;
						right: 0;
					}
				}

				.__over-3 {
					span {
						left: 0;
					}
				}
			}
		}

		&.__item-2 {
			a {
				margin-top: 76px;
			}

			.detail-popup__item-img {
				background-image: url("/images/main/FAQ.png");
				width: 80%;
				flex-direction: column;
			}

			.detail-popup__item-overflow {
				&.__over-1 {
					width: 65%;
					height: 100%;
					left: 1px;

					span {
						width: calc(100% - 25px);
						height: 87%;
						right: 25px;
						bottom: 0;
					}
				}

				&.__over-2 {
					width: 35%;
					height: 65%;

					span {
						width: calc(100% - 25px);
						height: calc(100% - 25px);
						left: 25px;
						bottom: 25px;
					}
				}

				&.__over-3 {
					height: 35%;
					width: 35%;
					bottom: 1px;

					span {
						width: calc(100% - 25px);
						height: 69%;
						left: 25px;
						top: 25px;
					}
				}
			}

			&.__animation {
				.__over-1 {
					span {
						right: 0;
					}
				}

				.__over-2 {
					span {
						left: 0;
						bottom: 0;
					}
				}

				.__over-3 {
					span {
						left: 0;
						top: 0;
					}
				}
			}
		}

		&.__item-3 {
			.detail-popup__item-img {
				background-image: url("/images/main/Chairman.jpg");
			}
		}

		&.__item-4 {
			a {
				margin-top: 46px;
			}

			.detail-popup__item-img {
				background-image: url("/images/main/RnD.jpg");
				width: 90%;
				flex-direction: column;
			}

			.detail-popup__item-overflow {
				&.__over-1 {
					width: 70%;
					height: 65%;
					top: 1px;

					span {
						width: 100%;
						height: 76%;
						right: 25px;
						bottom: 25px;
					}
				}

				&.__over-2 {
					width: 70%;
					height: 35%;

					span {
						width: 80%;
						height: calc(100% - 25px);
						top: 25px;
						right: 25px;
					}
				}

				&.__over-3 {
					height: 100%;
					width: 30%;
					right: 1px;

					span {
						width: calc(100% - 25px);
						height: 91%;
						left: 25px;
						top: 0;
					}
				}
			}

			&.__animation {
				.__over-1 {
					span {
						right: 0;
						bottom: 0;
					}
				}

				.__over-2 {
					span {
						top: 0;
						right: 0;
					}
				}

				.__over-3 {
					span {
						left: 0;
					}
				}
			}
		}

		&.__item-5 {
			a {
				margin-top: 46px;
			}

			.detail-popup__item-img {
				background-image: url("/images/main/Risk.jpg");
				width: 95%;
				flex-direction: column;
			}

			.detail-popup__item-overflow {
				&.__over-1 {
					width: 60%;
					height: 100%;
					left: 1px;

					span {
						width: 75%;
						height: 81%;
						right: 25px;
						bottom: 9%;
					}
				}

				&.__over-2 {
					width: 40%;
					height: 65%;

					span {
						width: calc(100% - 25px);
						height: calc(100% - 25px);
						left: 25px;
						bottom: 25px;
					}
				}

				&.__over-3 {
					height: 35%;
					width: 40%;
					bottom: 1px;

					span {
						width: calc(100% - 25px);
						height: calc(100% - 25px);
						left: 25px;
						top: 25px;
					}
				}
			}

			&.__animation {
				.__over-1 {
					span {
						right: 0;
					}
				}

				.__over-2 {
					span {
						left: 0;
						bottom: 0;
					}
				}

				.__over-3 {
					span {
						left: 0;
						top: 0;
					}
				}
			}
		}

		&.__item-6 {
			a {
				margin-top: 53px;
			}

			.detail-popup__item-img {
				background-image: url("/images/main/KeyPerformance.jpg");
				width: 90%;
				flex-direction: column;
			}

			.detail-popup__item-overflow {
				&.__over-1 {
					width: 60%;
					height: 60%;
					top: 1px;

					span {
						width: 75%;
						height: calc(100% - 25px);
						right: 25px;
						bottom: 25px;
					}
				}

				&.__over-2 {
					width: 60%;
					height: 40%;

					span {
						width: 100%;
						height: calc(100% - 25px);
						right: 25px;
						top: 25px;
					}
				}

				&.__over-3 {
					height: 100%;
					width: 40%;
					right: 1px;

					span {
						width: calc(100% - 25px);
						height: 81%;
						top: 9%;
						left: 25px;
					}
				}
			}

			&.__animation {
				.__over-1 {
					span {
						right: 0;
						bottom: 0;
					}
				}

				.__over-2 {
					span {
						right: 0;
						top: 0;
					}
				}

				.__over-3 {
					span {
						left: 0;
					}
				}
			}
		}

		&.__item-7 {
			a {
				margin-top: 46px;
			}

			.detail-popup__item-img {
				background-image: url("/images/main/ceo.png");
				width: 95%;
				flex-direction: column;
			}

			.detail-popup__item-overflow {
				&.__over-1 {
					width: 70%;
					height: 40%;
					top: 1px;

					span {
						width: 100%;
						height: 70%;
						right: 25px;
						bottom: 25px;
					}
				}

				&.__over-2 {
					width: 70%;
					height: 60%;

					span {
						width: 70%;
						height: calc(100% - 25px);
						right: 25px;
						top: 25px;
					}
				}

				&.__over-3 {
					height: 100%;
					width: 30%;
					right: 1px;

					span {
						width: 100%;
						height: 84%;
						top: 0;
						left: 25px;
					}
				}
			}

			&.__animation {
				.__over-1 {
					span {
						right: 0;
						bottom: 0;
					}
				}

				.__over-2 {
					span {
						right: 0;
						top: 0;
					}
				}

				.__over-3 {
					span {
						top: 0;
						left: 0;
					}
				}
			}
		}

		&.__item-8 {
			a {
				margin-top: 46px;
			}

			.detail-popup__item-img {
				background-image: url("/images/main/operation.jpg");
				width: 90%;
			}

			.detail-popup__item-overflow {
				&.__over-1 {
					width: 100%;
					height: 40%;
					top: 1px;

					span {
						width: 85%;
						height: calc(100% - 25px);
						right: 50px;
						bottom: 25px;
					}
				}

				&.__over-2 {
					width: 55%;
					height: 60%;

					span {
						width: calc(100% - 25px);
						height: calc(100% - 25px);
						right: 25px;
						top: 25px;
					}
				}

				&.__over-3 {
					height: 60%;
					width: 45%;
					right: 1px;

					span {
						width: 100%;
						height: 73%;
						top: 25px;
						left: 25px;
					}
				}
			}

			&.__animation {
				.__over-1 {
					span {
						bottom: 0;
					}
				}

				.__over-2 {
					span {
						top: 0;
						right: 0;
					}
				}

				.__over-3 {
					span {
						top: 0;
						left: 0;
					}
				}
			}
		}

		&.__item-9 {
			a {
				margin-top: 46px;
			}

			.detail-popup__item-img {
				background-image: url("/images/main/bisiness.png");
			}

			.detail-popup__item-overflow {
				&.__over-1 {
					width: 50%;
					height: 50%;
					left: 1px;

					span {
						width: 70%;
						height: 73%;
						right: 25px;
						bottom: 25px;
					}
				}

				&.__over-2 {
					width: 50%;
					height: 50%;

					span {
						width: calc(100% - 25px);
						height: calc(100% - 25px);
						left: 25px;
						bottom: 25px;
					}
				}

				&.__over-3 {
					height: 50%;
					width: 100%;
					bottom: 1px;

					span {
						width: 100%;
						height: calc(100% - 25px);
						top: 25px;
						left: 0;
					}
				}
			}

			&.__animation {
				.__over-1 {
					span {
						right: 0;
						bottom: 0;
					}
				}

				.__over-2 {
					span {
						left: 0;
						bottom: 0;
					}
				}

				.__over-3 {
					span {
						top: 0;
					}
				}
			}
		}
	}

	@media (max-width: 1440px) {
		&__cover {
			padding-left: 0;
		}

		&__nav {
			left: 0;
			height: 474px;
		}

		&__list {
			&-item {
				padding: 14px;
			}
		}

		&__item {
			&-title {
				font-size: 60px;
			}

			&-description {
				font-size: 16px;
			}

			&-link {
				height: 32px;
			}
		}
	}

	@media (max-width: 1024px) {
		&__cover {
			width: calc(100% - 64px);
			padding-right: 220px;
		}

		&__nav {
			height: 400px;
		}

		&__list {
			font-size: 14px;

			&-item {
				padding: 0 14px;
				height: 56px;

				&:before {
					height: 32px;
				}
			}
		}

		&__item {
			&-title {
				font-size: 50px;
			}

			&-description {
				font-size: 14px;
			}

			&-link {
				height: 28px;
				margin-top: 40px;
				width: 154px;
				font-size: 12px;
			}
		}
	}

	@media (max-width: 768px) {
		&__cover {
			width: calc(100% - 32px);
			padding-right: 0;
			padding-left: 40px;
		}

		&__close {
			left: -40px;
		}

		&__nav {
			height: 88px;
		}

		&__indication {
			display: flex;
		}

		&__list {
			display: none;
		}

		&__item {
			background-size: cover !important;

			&-title {
				font-size: 46px;
			}
		}
	}

	@media (max-width: 414px) {
		padding-bottom: 32px;

		&__cover {
			padding-left: 24px;
		}

		&__close {
			left: -32px;
		}

		&__nav {
			left: -8px;
		}

		&__item {
			&-title {
				font-size: 32px;
			}
		}
	}
}

span.mp-text-bg {
	background-color: #FCB53B;
}

.detail-popup__item-link {
	&:visited {
		width: 194px;
		height: 38px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ffffff;
		background: #EE2D24;
		font-size: 16px;
		text-transform: uppercase;
		font-family: 'Franklin Gothic Demi', sans-serif;

		position: relative;
		z-index: 1;
		text-decoration: none;
	}

	&:visited:hover {
		background: #F57F29;
	}
}

.no-event-link {
	color: #404040;
	text-decoration: none;

	&:hover {
		color: #404040;
		text-decoration: none;
		background-color: transparent;
	}

	&:visited {
		color: #404040;
		text-decoration: none;
		background-color: transparent;
	}

	&:visited:hover {
		color: #404040;
		text-decoration: none;
		background-color: transparent;
	}

}

@media (min-width: 1024px) and (max-width: 1366px) {
	.__item-3 {
		.ebs-link {
			bottom: 21%;
		}
	}
}

.browser-ie {
	.detail-popup__list-wrap {
		overflow: hidden;

		.detail-popup__list-item {
			@include font-size(16px);
			// justify-content: flex-start;

			&:after {
				margin-left: 10px;
			}

			span {
				flex-grow: 1;
			}
		}
	}

	.main__item {
		img {
			width: 22px;
		}
	}

	@media (min-width: 1024px) and (max-width: 1366px) {
		.detail-popup__list-wrap {
			overflow: hidden;

			.detail-popup__list-item {
				@include font-size(16px);
				// justify-content: flex-start;
				padding-left: 7px;

				&:after {
					margin-left: 10px;
				}
			}
		}

		.mp-text-bg {
			line-height: 1.2;
		}

		.ceo-header {
			position: absolute;
			bottom: 7px;
		}
	}
}

.detail-popup__item-description {
	.detail-popup__item-link {
		font-weight: 600;
	}
}
