$custom-links--color: $color-ironside-gray;
$custom-links--color-hover: $color-mountain-mist;
$custom-links--color-active: $color-celeste;

$custom-links--tooltip-bg: $color-taupe-gray;
$custom-links--tooltip-color: $color-white;

$custom-links--menu-color: $color-white;
$custom-links--menu-color-hover: $color-mountain-mist;
$custom-links--menu-color-active: $color-tapa-gray;
$custom-links--menu-bg: $color-tundora;

@if ($contrast) {
	$custom-links--color: $base-text-color;
	$custom-links--color-hover: $color-mountain-mist;
	$custom-links--color-active: $color-celeste;

	$custom-links--tooltip-bg: $color-taupe-gray;
	$custom-links--tooltip-color: $color-white;

	$custom-links--menu-color: $color-white;
	$custom-links--menu-color-hover: $color-mountain-mist;
	$custom-links--menu-color-active: $color-tapa-gray;
	$custom-links--menu-bg: $color-tundora;
}

.b-custom-links {
	@include font-size(12px);

	.b-tooltip {
		border-bottom: none;
	}

	&__link {
		position: relative;
		color: $custom-links--color;
		text-decoration: none;

		&:visited {
			color: $custom-links--color;
		}

		&:hover,
		&:visited:hover {
			color: $custom-links--color-hover;
		}

		&:active,
		&:visited:active {
			color: $custom-links--color-active;
		}
	}

	&__link--static {
		color: $custom-links--color;
		text-decoration: none;
	}
}

.b-custom-links--icons {
	.b-custom-links__link-icon {
		position: relative;
		display: inline-block;
	}
}

.b-custom-links--columns {
	.b-custom-links__list {
		display: inline-block;
		vertical-align: top;
	}
}

.b-custom-links--inline {
	.b-custom-links__item {
		display: inline-block;
		@include rem(padding-left, 15px);
	}
}

.b-custom-links--footer-privacy,
.b-custom-links--footer {
	font-size: 16px;
	.b-custom-links__item {
		padding: 0;
		margin-right: 2vw;
	}
	.b-custom-links__link {
		font-size: 16px;
		line-height: 18px;
		text-decoration: underline;

		color: $color-eclipse;
		opacity: 1;
		transition: color 0.5s, opacity 0.5s;
		background: transparent;

		&:hover {
			opacity: 0.6;
			background: transparent;
		}
		&:active {
			opacity: 1;
			color: $color-supernova;
			background: transparent;
		}
	}

	.reportType--csr & {
		
		.b-custom-links__link{
			font-family: Arial;
			color: $color-night-rider;

			&:hover {
				color: $color-nobel !important;
				opacity: 1;
			}
			&:active {
				color: $color-supernova-kco !important;
			}


			@include breakpoint(xs) {
				@include font-size(15px);
			}
		}
	}
}
.b-custom-links--footer {
	margin-left: 24px;
	.b-custom-links__link {
		font-size: 18px;
	}
}

.b-custom-links--menu-mobile {
	.b-custom-links__list {
		display: flex;
		flex-wrap: wrap;
		padding: 16px 0;
		border-top: 1px solid rgba(0, 0, 0, 0.4);
	}

	.b-custom-links__item {
		padding: 0 15px 0 0;
	}

	.b-custom-links__link {
		font-size: 16px;
		line-height: 18px;

		background: transparent;
		&:hover,
		&:focus,
		&:active {
			background: transparent;
		}

		color: $color-eclipse;
		text-decoration: none;

		&:visited {
			color: $color-eclipse;
		}

		&:hover,
		&:visited:hover {
			color: $color-pumpkin;
		}

		&:active,
		&:visited:active {
			color: $color-supernova;
		}
	}
}


.b-custom-links--menu-horizontal-cols {
	border-top: 1px solid rgba(0, 0, 0, 0.4);
	padding: 20px 0;
	display: flex;
	justify-content: flex-end;

	.b-custom-links__link {
		@include font(400, FranklinGothicBook);
		font-size: 16px;
		line-height: 18px;
		background: transparent;
		
		color: $color-eclipse;
		&:hover {
			color: $color-pumpkin;
			background: transparent;
		}
		&:active {
			color: $color-supernova;
			background: transparent;
		}
	}

	.reportType--csr & {
		border-color: $color-nobel;

		.b-custom-links__link {
			font-family: Arial;
			color: $color-night-rider;
			&:hover {
				color: $color-pumpkin-kco;
				background: transparent;
			}
			&:active {
				color: $color-supernova-kco;
				background: transparent;
			}
		}
	}
}

// Стили для ссылок в варианте меню menu-content
.b-custom-links--menu-horizontal-content {
	width: 100%;
	background-color: $custom-links--menu-bg;
	@include rem(padding, 5px 5px 5px 10px);

	.b-custom-links__link {
		position: relative;
		color: $custom-links--menu-color;
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
		@include rem(padding-left, 30px);

		&:focus {
			color: $custom-links--menu-color;
		}

		&:hover,
		&:visited:hover {
			color: $custom-links--menu-color-hover;
		}

		&:active,
		&:visited:active {
			color: $custom-links--menu-color-active;
		}

		.b-custom-links__link-text {
			display: inline-block;
			text-decoration: underline;
		}

		.b-custom-links__link-icon {
			position: absolute;
			display: block;
			left: 0;

			&:before {
				@include font-size(18px);
			}
		}
	}

	.b-custom-links__link--static {
		.b-custom-links__link-text {
			color: $custom-links--color;
			text-decoration: none;
		}
	}

	.b-custom-links__link--questions-answers {
		.b-custom-links__link-icon {
			@include rem(top, 6px);
			@include webfont-icon($webfont-icon--clipboard);
		}
	}

	.b-custom-links__link--events {
		.b-custom-links__link-icon {
			top: 0;
			@include webfont-icon($webfont-icon--file-excel);
		}
	}

	.b-custom-links__link--sitemap {
		.b-custom-links__link-icon {
			@include rem(top, -1px);
			@include webfont-icon($webfont-icon--tree);
		}
	}
}

@if ($responsive) {
	@include breakpoint(md) {
		// .b-custom-links {
		// 	.b-custom-links__item {
		// 		display: block;
		// 	}
		// }

		.b-custom-links--footer {
			.b-custom-links__link {
				line-height: 1;
			}
		}

		.b-custom-links--menu-horizontal-content {
			.b-custom-links__item {
				@include rem(padding-top, 5px);
			}
		}
	}
}
