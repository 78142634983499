%wrapper {
	width: 100%;
	display: block;

	@if $responsive == false {
		min-width: $site-width;
	} @else {
		min-width: $site-min-width;
	}
}

%in-wrapper {
	display: block;
	margin: 0 auto;
	width: $site-width;

	@if $responsive == false {
	}
}

%hardware-acceleration {
	transform: translate3d(0, 0, 0);
}

html {
	font-size: $base-font-size;
	//Prevent iOS text size adjust after orientation change, without disabling user zoom.
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body,
html {
	@extend .entire-height;
}

body {
	color: $base-text-color;
	line-height: $base-line-height;
	@extend %text--body;

	&.reportType--csr {
		font-family: Arial;
		color: $color-night-rider;
	}
}

// Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
b,
strong {
	font-weight: bold;
}

dfn,
em,
i {
	font-style: italic;
}

// Prevent `sub` and `sup` affecting `line-height` in all browsers.
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

// Remove border when inside `a` element in IE 8/9/10.
img {
	border: 0;
}

// Correct overflow not hidden in IE 9/10/11.
svg:not(:root) {
	overflow: hidden;
}

// Address differences between Firefox and other browsers.
hr {
	box-sizing: content-box;
	height: 0;
}

// Contain overflow in all browsers.
pre {
	overflow: auto;
}

figure {
	margin: 0;
}

// Content base style
.content-area {
	// &:not(.content-area--disable-owl) > * + * {
	// 	// Совиный селектор
	// 	// http://frontender.info/axiomatic-css-and-lobotomized-owls/
	// 	margin-bottom: 1em;
	// }

	img {
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		display: inline-block;
		//flexbox hack for IE
		min-height: 1px;
	}

	h1 + h2 {
		margin-top: 1em;
	}

	h2 + h3 {
		margin-top: 1.2em;
	}
}

//flexbox hack for IE
[class^="col-"] {
	& > * {
		min-height: 1px;
	}
}

@include cursors($cursorProperty);
