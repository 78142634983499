.header-1 {
	@include font-size(38px);
	line-height: 40px;
	color: $color-black;
	@include font(900, 'Humanist');
	margin: 0 0 64px;
	text-transform: uppercase;

	.reportType--csr & {
		font-family: Arial;
	}
}

h1 {
	@extend .header-1;
}

.header-2 {
	@include font-size(28px);
	line-height: 34px;
	color: $color-pumpkin;
	@include font(900, 'Humanist');
	margin: 0 0 34px;

	.reportType--csr & {
		color: $color-pumpkin-kco;
		font-family: Arial;
	}

	@if ($responsive) {
		@include breakpoint(sm) {
			line-height: 1;
		}
		@include breakpoint(xs) {
			@include font-size(24px);
		}
	}
}

h2 {
	@extend .header-2;
}

.header-3 {
	@include font-size(22px);
	line-height: 26px;
	color: $color-cinnabar;
	@include font(900, 'Humanist');
	margin: 0 0 15px;

	.reportType--csr & {
		color: $color-harley-davidson-orange;
		font-family: Arial;
	}
}

h3 {
	@extend .header-3;
}

.header-4 {
	@include font-size(20px);
	margin-bottom: 1em;
	@include font(500, 'FranklinGothic');
}

h4 {
	@extend .header-4;
}

.header-5 {
	@include font-size(18px);
	margin-bottom: 1em;
}

h5 {
	@extend .header-5;
}

.header-6 {
	@include font-size(16px);
	font-weight: bold;
	margin-bottom: 1.2em;
}

h6 {
	@extend .header-6;
}

.header-icon {
	display: flex;
	align-items: center;
	margin-bottom: 0.83em;

	&__icon {
		margin-right: 12px;
	}

	&__title {
		margin: 0;
	}
}

.h_special {
	font-weight: bold;
}

@include breakpoint(xs) {
	.header-1 {
		line-height: 1;
		// font-size: 30px;
		font-size: 20px;
	}
}
