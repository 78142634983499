.content-area,
#colorbox,
.tooltipster-base {
	li {
		position: relative;
		text-align: left;

		&:first-child {
			margin-top: 0;
		}

		& + li {
			margin: 0.5em 0;
		}
	}

	ul {
		margin-bottom: 11px;

		.reportType--csr & {
			font-family: Arial;
		}

		ol {
			li {
				padding-left: 1.5em;
			}
		}

		ul {
			margin-top: 10px;
		}

		& > li {
			padding-left: 15px;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				background-color: $color-pumpkin;
				top: calc(0.4em * 1.2);
				border-radius: 100%;
				left: 0;
				width: 7px;
				height: 7px;
			}

			ul li {
				&::before {
					background-color: rgba(245, 127, 41, .7);
				}
				ul li {
					&::before {
						background-color: rgba(245, 127, 41, .4);
					}
				}
			}
		}

		ol {
			counter-reset: list-numbers;

			li {
				&:before {
					background-color: transparent;
				}
			}
		}
	}

	ul.current-color {
		& > li:before {
			background-color: currentColor;
			border-color: currentColor;
		}
	}

	ul.red {
		& > li:before {
			background-color: $color-cinnabar;
			border-color: $color-cinnabar;
		}
	}

	ol.current-color {
		& > li:before {
			color: currentColor;
		}
	}

	//Список без маркеров
	.no-markers {
		li {
			padding-left: 1.5em;

			&:before {
				display: none;
			}
		}
	}

	//Нумерованный список
	ol {
		counter-reset: list-numbers;
		margin-bottom: 11px;

		.reportType--csr & {
			font-family: Arial;
		}

		& > li {
			padding-left: 20px;

			&:before {
				content: counters(list-numbers, ".")".";
				counter-increment: list-numbers;
				display: block;
				width: 20px;
				position: absolute;
				top: 0;
				left: 0;
				color: $color-pumpkin;
			}

			ol li {
				&::before {
					color: rgba(245, 127, 41, .7);
					left: 1px;
				}

				ol li {
					&::before {
						color: rgba(245, 127, 41, .4);
					}
				}
			}
		}

		ol {
			padding-top: 0;
			margin-top: 10px;
		}
	}

	ol {
		ol li ol li {
			padding-left: 50px;

			// &:before {
			// 	left: -0.75em;
			// }
		}

		& > li {
			ol > li {
				padding-left: 35px;
			}
		}
	}

	ul ol,
	ol ul,
	ul ul,
	ol ol {
		margin: 4px 0;
	}
}

.b-table {
	ul {
		& > li {
			&:before {
				top: calc(0.3em * 1.2);
			}
		}

		ul li {
			&:before {
				top: calc(0.35em * 1.2);
			}

			ul li {
				&:before {
					top: calc(0.375em * 1.2);
				}
			}
		}
	}
}

@mixin reset-counter($counterName) {
	ol {
		counter-reset: $counterName;

		li {
			&:before {
				content: counters($counterName, ".");
				counter-increment: $counterName;
			}
		}
	}

	ul li ol {
		counter-reset: $counterName;

		li {
			&:before {
				content: counters($counterName, ".");
				counter-increment: $counterName;
			}
		}
	}

	ol li ul li {
		&:before {
			text-indent: -9999px;
		}
	}
}

.b-accordion {
	@include reset-counter(list-accordion);
}

.b-table {
	@include reset-counter(list-table);
}

.b-tabs {
	@include reset-counter(list-tabs);
}
