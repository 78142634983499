$useful-links--btn-bg: $color-ironside-gray;
$useful-links--btn-color: $color-white;
$useful-links--bg: $color-eallery;
$useful-links--link-color: $color-eclipse2;
$useful-links--link-color-hover: $color-tapa-gray;

@if ($contrast) {
	$useful-links--btn-bg: $color-light-gray;
	$useful-links--btn-color: $base-text-color;
	$useful-links--bg: $color-light-gray;
	$useful-links--link-color: $color-ironside-gray;
	$useful-links--link-color-hover: $color-mine-shaft;
}

.b-useful-links {
	@include rem(margin-top, $grid-gutter-width);
	@include rem(padding, $grid-gutter-width);

	.reportType--csr & {
		font-family: Arial;
	}


	&__link {
		@extend %disable-visited;
		display: inline-block;
		position: relative;
		@include font-size(16px);
		line-height: 19px;
		@include rem(padding-top, 7px);
		@include rem(padding-bottom, 4px);
		text-decoration: none;
		color: $useful-links--link-color;
		opacity: .7;
		background-color: transparent;

		&:hover {
			color: $useful-links--link-color;
			opacity: 1;
			text-decoration: underline;
			background-color: transparent;
		}

		&:active {
			color: $useful-links--link-color;
			text-decoration: underline;
			background-color: transparent;
		}

		.reportType--csr & {
			color: $color-dim-gray;

			&:hover {
				color: $color-night-rider;
				background-color: transparent;
			}
	
			&:active {
				color: $color-nobel;
				background-color: transparent;
			}
		}
	}

	ul {
		border-left: 5px solid $color-pumpkin;
		margin-top: -1px;
		padding-bottom: 7px;

		.reportType--csr & {
			font-family: Arial;
			border-color: $color-pumpkin-kco;
		}
	}
}
