.inset {
	position: relative;
	color: $color-davys-grey;
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	color: $color-eclipse2;
	border: 1px solid $color-eclipse2;
	border-left: 0;
	border-right: 0;
	margin-bottom: 15px;
	padding: 19px 0 0;
}

.inset--with-title {
	margin-top: 25px;
	padding: 0 0 20px 23px;
	border-top: none;
	border-right: 1px solid $color-pumpkin;
	border-bottom: 1px solid $color-pumpkin;
	margin-bottom: 40px;

	@include font(400, FranklinGothicBook);
	font-size: 18px;
	line-height: 25px;
	color: $color-eclipse2;

	.reportType--csr & {
		font-family: Arial;
		color: $color-night-rider;
	}

	p {
		padding-right: 20px;
	}

	figcaption {
		@include font(900, Humanist);
		font-size: 20px;
		line-height: 28px;
		color: $color-black;
		background: $color-supernova-light;
		position: relative;
		padding-left: 5px;
		margin-bottom: 12px;

		.reportType--csr & {
			font-family: Arial;
		}

		&:before {
			content: "";
			position: absolute;
			right: 100%;
			bottom: 100%;
			width: 23px;
			height: 23px;
			display: block;
			background: $color-pumpkin;
		}
	}

	&--with-image {
		margin-top: 100px;

		img.caption-image {
			height: 100px;
			position: absolute;
			top: -100px;
			right: 0;

			&:before {
				content: "";
				position: absolute;
				background: $color-pumpkin;
				.reportType--csr & {
					background: $color-supernova-kco;
				}
			}
		}

		figcaption {
			background: $color-pumpkin;

			&:before {
				background: $color-supernova-light;
			}
		}
	}

	& > *:last-child {
		margin-bottom: 0;
	}
}

.inset--with-image {
	position: relative;
	@include font(900, 'Humanist');
	font-size: 18px;
	line-height: 120%;
	color: $color-eclipse;
	border: 0;
	padding: 0;

	.reportType--csr & {
		font-family: Arial;
		color: $color-night-rider;
	}

	.inset__icon {
		width: 74px;
		margin-bottom: 22px;
	}

	a {
		color: $color-suva-grey;
		text-decoration: none;


		&:hover,
		&:focus,
		&:visited:hover,
		&:visited:focus {
			color: $color-suva-grey;
			text-decoration: underline;
			background-color: transparent;

		}

		&:active,
		&:visited:active {
			color: $color-eclipse;
			background-color: transparent;
		}

		.reportType--csr & {
			color: $color-nobel;

			&:hover,
			&:focus,
			&:visited:hover,
			&:visited:focus {
				background-color: transparent;
				color: $color-nobel;
			}

			&:active,
			&:visited:active {
				color: $color-night-rider;
				background-color: transparent;
				text-decoration: underline;
			}
		}
	}

	&__text {
		position: absolute;
		padding: 7px 7px;
		background-color: $color-mountain-mist;
		opacity: 0.7;
		color: $color-black;
		top: 0;
	}
}


.inset--bold {
	@include font(900, 'Humanist');
	font-size: 18px;
	line-height: 22px;
	color: $color-black;
	border: 0;

	
	.reportType--csr & {
		font-family: Arial;
		color: $color-black;
	}
}

.inset--date {
	border: 0;
	color: $color-black;
	@include font(900, 'Humanist');
		
	.reportType--csr & {
		font-family: Arial;
	}
	.inset__date {
		font-size: 54px;
		line-height: 54px;
	}

	.inset__mount {
		font-size: 18px;
		line-height: 18px;
		position: relative;
		width: auto;
		display: inline-block;
		margin-top: -3px;
		margin-bottom: 14px;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -3px;
			width: 100%;
			height: 3px;
			background-color: $color-supernova;
		}
	}

	.inset__text {
		font-size: 20px;
		line-height: 20px;
		text-transform: uppercase;
	}
}