.b-buttons-panel--in-column {
	position: relative;

	.b-buttons-panel__trigger {
		display: block;
		background-color: transparent;
		color: $buttons-panel--trigger-color;
		font-size: 0;
		padding: 0;
		@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */


		&:before {
			display: block;
			@include font-size($buttons-panel--trigger-icon-size);
			color: $color-suva-grey2;

			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover,
		&:focus {
			color: $buttons-panel--trigger-color-hover;
			background-color: transparent;
		}

		&:active,
		&.is-expand {
			color: $buttons-panel--trigger-color-active;
			background-color: transparent;

		}

		&[disabled] {
			color: $buttons-panel--trigger-color;
		}

		.reportType--csr & {
			color: rgba($color-nobel, 0.7);
			font-family: Arial;
			&:before {
				color: rgba($color-nobel, 0.7);
			}
			&:hover,
			&:focus {
				color: $buttons-panel--trigger-color-hover;
				background-color: transparent;
			}

			&:active,
			&.is-expand {
				// transform: none;
				color: $buttons-panel--trigger-color-active;
				background-color: transparent;

			}

			&[disabled] {
				color: rgba($color-nobel, 0.7);
			}
		}
	}

	.b-buttons-panel__trigger--tools {
		@include webfont-icon($webfont-icon--i-tools);

		&:before {
			color: $color-suva-grey2;
		}
		&:hover,
		&:focus {
			&:before {
				color: $buttons-panel--trigger-color-hover;
			}
		}
		&:active,
		&.is-expand {
			&:before {
				color: $buttons-panel--trigger-color-active;
			}
		}
		&[disabled] {
			&:before {
				color: $buttons-panel--trigger-color;
			}
		}

		.reportType--csr & {
			font-family: Arial;
			color: rgba($color-nobel, 0.7);
			&:before {
				color: rgba($color-nobel, 0.7);
			}
			&:hover,
			&:focus {
				&:before {
					color: $buttons-panel--trigger-color-hover;
				}
			}
			&:active,
			&.is-expand {
				&:before {
					color: $buttons-panel--trigger-color-active;
				}
			}
			&[disabled] {
				&:before {
					color: rgba($color-nobel, 0.7);
				}
			}
		}
	}

	.b-buttons-panel__list {
		position: relative;
		top: 100%;
		right: 0;
		margin-top: 7px;
		z-index: 20;
		border-top: 12px solid $color-supernova;

		.reportType--csr & {
			border-color: $color-supernova-kco;
		}
	}


	.b-buttons-panel__link {
		border: 1px solid $color-black-opacity2;
		border-top: none;
		white-space: nowrap;
		padding: 16px 15px 12px 48px;

		font-size: 16px;

		&:before {
			@include rem(left, 25px);
			font-size: 16px;
		}

		.reportType--csr & {
			border-color: $color-nobel;
			color: $color-dim-gray;
			background: transparent;

			&:hover,
			&:visited:hover {
				color: $color-night-rider;
				background-color: $color-white-smoke;

				&:before {
					color: $color-night-rider;
				}
			}
			&:active,
			&:visited:active {
				color: $color-black;
				background-color: $color-whisper;

				&:before {
					color: $color-black;
				}
			}
		}
	}
	.b-buttons-panel__item {
		margin: 0;
		padding: 0;

		&:first-child {
			.b-buttons-panel__link {
				border-top: 1px solid $color-black-opacity2;
			}
		}

		&:before {
			content: none;
		}
	}

	.b-buttons-panel__sublist--share,
	.b-buttons-panel__sublist--social {
		@extend %clearfix;
		@include rem(padding, 0 10px);
	}

	.b-buttons-panel__sublist--share {
		background: transparent;
		position: absolute;
		bottom: 8px;
		right: 0;

		.reportType--csr--ru & {
			right: -7px;
		}
	}


	.b-buttons-panel__sublist-link--compare {
		text-align: center;
	}

	.b-buttons-panel__sublist:not([data-sublist-slide-type="bottom"]) {
		position: absolute;
		padding: 0;
		top: 0;

		@for $i from 1 through 15 {
			&[data-length="#{$i}"] {
				width: $buttons-panel--sublist-link-width * $i;
			}
		}

		&[data-sublist-slide-type="left"] {
			right: 100%;
		}

		&[data-sublist-slide-type="right"] {
			left: 100%;
		}
	}
}

.b-buttons-panel--in-column.b-buttons-panel--static {
	background-color: $buttons-panel--list-bg-color;
	@include rem(padding-top, $grid-gutter-width);
	@include rem(padding-bottom, $grid-gutter-width);

	.b-buttons-panel__title {
		display: block;
		@include rem(padding-left, $grid-gutter-width);
		@include rem(padding-bottom, $grid-gutter-width);
		@include font-size($buttons-panel--title-font-size);
		color: $buttons-panel--title-color;
	}

	.b-buttons-panel__link {
		white-space: normal;
	}
}

.b-buttons-panel--in-column.b-buttons-panel--compact {
	.b-buttons-panel__list {
		position: absolute;
		display: none;
		background-color: $buttons-panel--list-bg-color;
	}
}

// Панель с текстом в кнопке тригера
.b-buttons-panel--in-column.b-buttons-panel--button-with-text:not(.b-buttons-panel--download-pdf) {
	@include rem(max-width, 260px);

	.b-buttons-panel__trigger-controller {
		position: relative;
		width: 20px;
		height: 20px;
		margin-left: 14px;

		display: none;

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 2px;
			height: 14px;
			background-color: currentColor;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform .2s ease;
			border-radius: 5px;
		}

		&:before {
			right: 13px;
			transform: rotate(-44deg);
		}

		&:after {
			right: 4px;
			transform: rotate(44deg);
		}
	}

	.b-buttons-panel__trigger {
		display: flex;
		align-items: center;
		width: 100%;
		@include font-size($buttons-panel--trigger-text-font-size);
		padding-right: 20px;
		position: relative;

		@include font(500, FranklinGothic);
		font-size: 14px;
		line-height: 16px;

		&.is-expand .b-buttons-panel__trigger-controller {

			&:before {
				transform: rotate(44deg);
			}

			&:after {
				transform: rotate(-44deg);
			}
		}

		.reportType--csr & {
			font-family: Arial;
		}
	}

	.b-buttons-panel__link {
		white-space: normal;
	}

	.b-buttons-panel__list {
		// width: 100%;
		width: 260px;

		// left: -10px;
		right: -10px;//auto;
	}
}

// Панель скачать пдф
.b-buttons-panel--in-column.b-buttons-panel--download-pdf {
	width: 104px;

	.reportType--csr & {
		// width: 110px;
		width: auto;
	}

	.b-buttons-panel__trigger {
		@include webfont-icon($webfont-icon--i-pdf);
		padding-right: 16px;

		&:before {
			color: $color-suva-grey2;
		}
		&:hover,
		&:focus {
			&:before {
				color: $buttons-panel--trigger-color-hover;
			}
		}
		&:active,
		&.is-expand {
			// transform: none;
			&:before {
				color: $buttons-panel--trigger-color-active;
			}
		}
		&[disabled] {
			&:before {
				color: $buttons-panel--trigger-color;
			}
		}
	}

	.b-buttons-panel__trigger--with-text {
		display: flex;
		align-items: center;
		@include font-size($buttons-panel--trigger-text-font-size);

		@include font(500, FranklinGothic);
		font-size: 14px;
		line-height: 16px;

		// &:before {
		// 	margin-right: 9px;
		// }

		.b-buttons-panel__trigger-controller {
			display: none;
		}


		.reportType--csr & {
			font-family: Arial;
			white-space: nowrap;
			color: rgba($color-nobel, 0.7);
			&:before {
				color: rgba($color-nobel, 0.7);
			}
			&:hover,
			&:focus {
				color: $buttons-panel--trigger-color-hover;
				&:before {
					color: $buttons-panel--trigger-color-hover;
				}
			}
			&:active,
			&.is-expand {
				color: $buttons-panel--trigger-color-hover;
				&:before {
					color: $buttons-panel--trigger-color-active;
				}
			}
			&[disabled] {
				&:before {
					color: rgba($color-nobel, 0.7);
				}
			}
		}
	}

	.b-buttons-panel__link {
		white-space: normal;
		@include rem(padding-left, 50px);
	}

	.b-buttons-panel__link--pdf-spreads {
		@include webfont-icon($webfont-icon--i-spreads);
	}

	.b-buttons-panel__link--pdf-pages {
		@include webfont-icon($webfont-icon--i-pages);
	}
}

@include breakpoint(md) {
	.b-buttons-panel--in-column.b-buttons-panel--button-with-text:not(.b-buttons-panel--download-pdf) .b-buttons-panel__list {
		right: -16px;
		left: auto;
	}
}
@include breakpoint(xs) {
	.b-buttons-panel--in-column {
		.b-buttons-panel__sublist--share {
			width: auto;
			height: auto;
		}

		.b-buttons-panel__sublist-link {
			float: none;
		}
	}

	.b-buttons-panel--in-column .b-buttons-panel__trigger--tools,
	.b-buttons-panel--in-column.b-buttons-panel--download-pdf .b-buttons-panel__trigger {
		&.is-expand {
			@include webfont-icon($webfont-icon--i-close);
			&:before {
				font-size: 10px;
			}
		}
	}
}
