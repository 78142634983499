$report-selector_multi-button--link-active: $color-eclipse;

@if ($contrast) {
	$report-selector_multi-button--link-active: $color-white;
}

.b-report-selector--multi-button {
	position: relative;

	@include font(500, FranklinGothic);
	font-size: 14px;
	line-height: 16px;
	text-transform: uppercase;

	.b-report-selector__item {
		// border-left: 1px solid $color-black;
		@include rem(padding-left, 5px);

		&:first-child {
			border-left: none;
		}
		
		&.is-active {
			color: $report-selector_multi-button--link-active;
		}

		.reportType--csr & {
			font-family: Arial;
			&.is-active {
				color: $color-night-rider;
			}
		}
	}
	
	.b-report-selector__item--link {
		display: none;
	}
	
	.b-report-selector__link {
		color: $color-suva-grey2;
		opacity: 0.7;
		text-decoration: none;

		&:hover,
		&:active,
		&:focus {
			background: transparent;
		}

		@include font(500, FranklinGothic);
		font-size: 14px;
		line-height: 16px;
		text-transform: uppercase;
		&:visited {
			color: $color-suva-grey2;
		}
		&:visited:hover,
		&:hover {
			// color: black;
			// text-decoration: underline;
			border-bottom: 3px solid $color-suva-opacity;
			opacity: 1;
		}
		&:visited:active,
		&:active {
			color: $color-eclipse;
			border-bottom: 3px solid transparent;
			opacity: 1;
		}

		.reportType--csr & {
			font-family: Arial;
			opacity: 1;
			color: $color-nobel;
			// &:visited {
			// 	color: $color-nobel;
			// }
			// &:visited:hover,
			// &:hover {
			// 	// color: black;
			// 	// text-decoration: underline;
			// 	border-bottom: 3px solid $color-suva-opacity;
			// 	opacity: 1;
			// }
			// &:visited:active,
			// &:active {
			// 	color: $color-eclipse;
			// 	border-bottom: 3px solid transparent;
			// 	opacity: 1;
			// }
		}
	}
	
	@if ($responsive) {

		@include breakpoint(lg) {
			.b-report-selector__item {
				padding-left: 10px;
			}
		}
		@include breakpoint(sm) {
			font-size: 12px;
			line-height: 14px;
			.b-report-selector__link {
				font-size: 12px;
				line-height: 14px;
			}
			.b-report-selector__item {
				padding-left: 0;
				&:first-child {
					margin-bottom: 4px;
    				display: block;
				}
			}
		}
	}
	
}
