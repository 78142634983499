$title--text-color: $color-eclipse;
$title--link-color: $color-eclipse;
$title--link-color--hover: $color-eclipse;
$title--link-color--active: $color-suva-grey2;
$title--link-color--visited: $link-default-color;

@if ($contrast) {
	$title--text-color: $color-white;
}

.b-report-title {
	@include font(500, FranklinGothic);
	font-size: 14px;
	line-height: 16px;
	text-transform: uppercase;

	&__link {
		&:visited {
			color: $title--link-color--visited;
		}

		&:hover,
		&:focus {
			color: $title--link-color--hover;
		}

		&:active {
			color: $title--link-color--active;
			opacity: 0.7;
		}
	}
}
