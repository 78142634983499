.lead {
	font-size: 21px;
	line-height: 27px;
	color: $color-black;
	@include font(500, 'FranklinGothic');

	.reportType--csr & {
		font-family: Arial;
	}
}
