.l-layout-wrapper--1 {
	.browser-ie & {
		height: 100%;
	}

	.l-layout-wrapper__header.is-fixed {
		position: static;
		top: 0;
		z-index: 10;
		width: $site-width;
	}

	.l-header-top-line {
		display: flex;
		align-items: center;
		padding: 20px 50px 20px 0px;
		z-index: 4;
		// перекрыть навигацию
		// z-index: 12;
		position: relative;

		max-width: 1153px;

		&__logo {
			// display: none;
			flex-shrink: 0;
			margin-right: 30px;
		}

		&__title {
			// flex-shrink: 0;
			// flex-grow: 0;
			// flex-basis: 300px;
			// width: 300px;
		}

		&__tools {
			display: flex;
			justify-content: flex-end;
			flex-grow: 1;
		}
	}

	.l-header-top-nav {
		display: flex;
		// background: $color-pumpkin;
		position: relative;
		// z-index: 2;

		// z-index: 12;

		// max-width: 1153px;

		&:before {
			content: "";
			display: block;
			position: absolute;
			// bottom: 100%;
			// right: 100%;
			// height: 20px;
			// // width: calc((100vw - 1672px)/2);
			// width: 100vw;
			// background: $color-supernova;
			height: 1px;
			left: 50%;
			top: 0;
			margin-left: -50vw;
			width: 100vw;
			background: $color-black;
			z-index: 2;
		}
		// &:after {
		// 	content: "";
		// 	display: block;
		// 	position: absolute;
		// 	top: 0;
		// 	left: 100%;
		// 	height: 100%;
		// 	// width: calc((100vw - 1672px)/2);
		// 	width: 100vw;
		// 	background: $color-pumpkin;
		// }

		&__logo {
			flex-shrink: 0;
			margin-right: 12px;
		}

		&__menu {
			flex-grow: 1;
			flex-shrink: 1;
			position: relative;
			z-index: 3;
		}
	}

	.l-page {
		&__nav-breadcrumbs {
			width: 100%;
			max-width: calc(100% - 90px); // минус ширина page-nav'a
		}
	}

	.l-buttons-area {
		display: flex;
		align-items: center;

		&__item + .l-buttons-area__item {
			margin-left: 30px;
		}

		&__search {
			.search {
				width: auto;
				display: block;
			}
		}

		&__mobile-nav-panel {
			display: none;
		}
	}

	.l-footer {
		display: flex;

		&__inner {
			min-height: 55px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			position: relative;

			&:after {
				content: "";
				bottom: 100%;
				position: absolute;
				left: 0;
				height: 5px;
				width: 496px;
				background: $color-pumpkin;

				.reportType--csr & {
					border-color: $color-pumpkin-kco;
				}
			}

			&:before {
				content: "";
				bottom: 100%;
				position: absolute;
				right: 100%;
				height: 5px;
				width: 1000vw;
				background: $color-cinnabar;

				.reportType--csr & {
					border-color: $color-harley-davidson-orange;
				}
			}
		}

		&__social {
			margin-left: auto;
		}

		&__nav {
			text-align: right;
		}
	}
}

@if ($responsive) {
	.l-layout-wrapper--1 {
		.l-layout-wrapper__header.is-fixed {
			width: 100%;
			max-width: $site-width;
			min-width: $site-min-width;
		}
	}

	@include breakpoint(lg) {
		.l-layout-wrapper--1 {
			.l-header-top-line__logo {
				margin-right: 22px;
			}

			.l-header-top-line {
				max-width: 1018px;
				.reportType--csr & {
					max-width: 1153px;
				}
			}

			.l-footer__inner {
				&:before {
					right: 92%;
					z-index: 2;
				}

				&:after {
					width: 478px;
				}
			}
		}

	}
	@include breakpoint(md) {
		.l-layout-wrapper--1 {
			.l-header-top-line__logo {
				display: block;
			}

			.l-header-top-line {
				padding-right: 30px;
			}

			.l-header-top-nav__logo {
				display: none;
			}

			.l-footer__inner {
				&:before {
					right: 90%;
				}

				&:after {
					width: 268px;
				}
			}
		}
	}

	@media (max-width: 960px) {
		.l-layout-wrapper--1 {
			.reportType--csr & {
				.l-header-top-line {
					padding-bottom: 20px;
					padding-right: 16px;
					padding-left: 16px;
					margin: 0 -16px;
	
					height: 86px;
					border-bottom: 2px solid $color-black;
					align-items: flex-start;
					padding-top: 14px;
					position: relative;
				}
	
				.l-header-top-line__title {
					display: block;
					position: absolute;
					left: 16px;
					top: 41px;
					max-width: 260px;
				}
	
				.l-buttons-area {
					& > .l-buttons-area__search {
						display: none;
					}
	
					& > .l-buttons-area__pdf {
						display: none;
					}
				}
	
				.l-header-top-nav {
					display: none;
				}
	
				.l-page__nav {
					flex-direction: column;
					align-items: flex-start;
				}
	
				.l-buttons-area__mobile-nav-panel {
					display: block;
					position: absolute;
					right: 11px;
					top: 40px;
				}
			}
		}
	}

	@include breakpoint(sm) {
		.l-layout-wrapper--1 {
			.l-page {
				flex-direction: column;
			}

			.l-page__sidebar {
				flex-basis: auto;
				width: auto;
			}

			.l-page__nav-breadcrumbs {
				width: 100%;
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}

			.l-header-top-line {
				padding-bottom: 13px;
				padding-right: 0;
				padding-top: 13px;
			}

			.l-header-top-line__title {
				// display: none;
			}

			.l-buttons-area__item + .l-buttons-area__item {
				margin-left: 16px;
			}

			.l-header-top-nav {
				&:after {
					width: 32px;
				}
			}
		}
	}

	@media (max-width: 600px) {
		.l-layout-wrapper--1 {
			.reportType--csr--ru & {
				.l-footer__copyright {
					margin-bottom: 20px;
				}

				.l-footer__privacy {
					position: absolute;
					left: 0;
					bottom: 0;
				}

				.l-footer {
					padding-bottom: 17px;
				}

				.l-footer__social {
					margin-top: -5px;
					margin-right: -5px;
				}

				.b-social .b-social__item {
					margin-right: 0;
				}

				.l-footer__nav {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.l-footer__inner {
					min-height: 85px;
					align-items: flex-start;
					padding-top: 17px;
					.b-custom-links__item {
						display: block;
					}
				}
			}
		}
	}

	@include breakpoint(xs) {
		.l-layout-wrapper--1 {
			.l-header-top-line {
				padding-bottom: 20px;
				padding-right: 16px;
				padding-left: 16px;
				margin: 0 -16px;

				height: 86px;
				border-bottom: 2px solid $color-black;
				align-items: flex-start;
				padding-top: 14px;
				position: relative;
			}

			.l-header-top-line__title {
				display: block;
				position: absolute;
				left: 16px;
				top: 41px;
			}

			.l-buttons-area {
				& > .l-buttons-area__search {
					display: none;
				}

				& > .l-buttons-area__pdf {
					display: none;
				}
			}

			.l-header-top-nav {
				display: none;
			}

			.l-page__nav {
				flex-direction: column;
				align-items: flex-start;
			}

			.l-buttons-area__mobile-nav-panel {
				display: block;
				position: absolute;
				right: 11px;
				top: 40px;
			}

			.l-page__nav-breadcrumbs {
				max-width: 100%;
				@include rem(margin-bottom, 10px);
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}

			.l-footer__privacy {
				position: absolute;
				left: 0;
				bottom: 0;
			}

			.l-footer__copyright {
				margin-bottom: 20px;
			}

			.l-footer {
				padding-bottom: 17px;
			}

			.l-footer__inner {
				min-height: 70px;
				align-items: flex-start;
				padding-top: 17px;
			}

			.l-footer__social {
				margin-top: -5px;
				margin-right: -5px;
			}

			.b-social .b-social__item {
				margin-right: 0;
			}

			.l-footer__nav {
				position: absolute;
				right: 0;
				bottom: 0;
			}
		}
	}
	
}
