.b-indicator {
	display: block;
	color: $color-black;
	margin-bottom: 15px;

	&__value {
		display: inline-block;
		font-size: 20px;
		line-height: 20px;
		color: $color-pumpkin;
		@include font(900, 'Humanist');

		.reportType--csr & {
			font-family: Arial;
			color: $color-black;
		}
	}

	&__value--big {
		font-size: 54px;
		line-height: 64px;
		color: $color-pumpkin;
		display: inline-block;
		margin-bottom: -6px;

		.reportType--csr & {
			color: $color-black;
		}
	}

	&__value--small {
		@include font-size(39px);
	}

	&__value--overview {
		font-size: 54px;
		line-height: 54px;
	}

	&__value--arrow {
		@include webfont-icon($webfont-icon--up);

		&:before {
			vertical-align: top;
			font-size: 0.5em;
		}
	}

	&__value--arrow-up {}

	&__value--arrow-down {
		&:before {
			transform: rotate(180deg);
		}
	}

	&__value--print {
		display: none !important;
	}

	&__unit {
		display: inline-block;
		font-size: 25px;
		line-height: 18px;
		color: $color-pumpkin;

		.reportType--csr & {
			color: $color-black;
			font-family: Arial;
		}
	}

	&__unit--big {
		font-size: 20px;
		line-height: 18px;
		margin-bottom: 10px;
		@include font(900, 'Humanist');
		color: $color-pumpkin;
		border-bottom: 3px solid $color-supernova;

		.reportType--csr & {
			font-family: Arial;
			color: $color-black;
		}
	}

	&__unit--small {
		@include font-size(24px);
		font-weight: 400;
	}

	&__unit--bigger {
		font-size: 50px;
		line-height: 50px;
		color: $color-pumpkin;
		display: inline-block;

		.reportType--csr & {
			font-family: Arial;
			color: $color-black;
		}
	}

	&__text {
		display: block;
		font-size: 20px;
		line-height: 20px;
		@include font(900, 'Humanist');

		&--uppercase {
			text-transform: uppercase;
		}

		.reportType--csr & {
			font-family: Arial;
		}
	}

	&__text--big {
		@include font-size(18px);
		font-weight: 700;
	}

	&__text--small {
		@include font-size(12px);
	}

	&__value,
	&__unit,
	&__text {
		& + & {
			@extend %collapse--top;
		}
	}

	&__heading {
		font-size: 22px;
		line-height: 22px;
		@include font(900, 'Humanist');
		color: $color-black;

		.reportType--csr & {
			font-family: Arial;
		}
	}

	&__percent {
		position: relative;
		font-size: 12px;
		line-height: 14px;
		.reportType--csr & {
			font-family: Arial;
		}

		&.up {
			@include webfont-icon($webfont-icon--i-arrow);
			&::before {
				font-size: 12px;
				margin-right: 1px;
				transform: rotate(180deg);
			}
		}

		&.down {
			@include webfont-icon($webfont-icon--i-arrow);
			&::before {
				font-size: 12px;
			}
		}
	}
}

@media (max-width: 1400px) {
	.b-indicator__value--overview {
		font-size: 35px;
		line-height: 42px;
	}
}

@media (max-width: 1100px) and (min-width: 1055px) {
	.b-indicator__value--overview {
		font-size: 32px;
		line-height: 40px;
	}
}
