.b-logo {
	display: block;
	width: 115px;
	height: 18px;

	background: transparent;
	&:hover,
	&:active,
	&:focus {
		background: transparent;
	}

	svg {
		width: 100%;
		height: 100%;
	}

	&__image {
		width: 100%;
		height: 100%;
	}
}

.logo-svg {
	width: 100%;
	height: 100%;
}

@include breakpoint(xs) {
	.b-logo {
		width: 80px;
		height: 18px;



		svg {
			display: block;
			margin: 0;
		}
	}
}