//text
$base-text-color: $color-eclipse2;

//errors
$error-text-color: #c7191a;
$error-form-bg: #fea8a6;

//links
$link-default-color: $color-black;
$link-hover-color: $color-white;
$link-active-color: $color-white;
$link-visited-color: $color-white;

//buttons
$btn--bg: $color-black;
$btn--bg-hover: $color-black;
$btn--bg-active: $color-supernova;
$btn--bg-visited: #919191;
$btn--color: $color-black;
$btn--color-hover: $color-white;
$btn--color-active: $color-white;

//checkbox
$checkbox--color: $color-black;
$checkbox--color-hover: $color-supernova;
$checkbox--color-checked: $color-black;
$checkbox--color-partly: $color-gray;
$checkbox--bg-color: $color-white;

// xls, zoom, analysis buttons
$tools--color: $color-ironside-gray;
$tools--color-hover: $color-black;
$tools--color-active: $color-carrot-orange;

// tooltips
$t_tooltips--bg-color: $color-ironside-gray;
$t_tooltips--color: $color-white;


// not-page
$not-page-404: $color-supernova;

@if ($contrast) {
	$base-text-color: $color-white;
	$error-text-color: #ff0000;
	$error-form-bg: #fff;
	$link-default-color: $base-text-color;
	$link-hover-color: $color-carrot-orange;
	$link-visited-color: #252525;
	$btn--bg: #ededed;
	$btn--bg-hover: #484848;
	$btn--bg-active: #252525;
	$btn--color: #656565;
	$btn--color-hover: $color-white;
	$btn--color-active: $color-white;
	$tools--color: $color-ironside-gray;
	$tools--color-hover: $color-black;
	$tools--color-active: $color-carrot-orange;
}
