.body--operational {
	$white: #FFFFFF;
	$light-grey: #C6C6C6;
	$grey: #808285;
	$dark-grey: #58595B;
	$red: #EE2D24;
	$orange: #F57F29;
	$yellow: #FFCB05;

	h2 {
		color: #404040;
		font-size: 28px;
		text-transform: uppercase;
		@include font(900, Humanist);
		margin-bottom: 28px;

		&.section {
			margin-bottom: 0px;
		}
	}

	.tough {
		font-size: 20px;
		line-height: 120%;
		@include font(900, Humanist);
		margin: 24px 0 4px;
	}

	.b-indicator {
		&__percent {
			font-size: 14px;
			font-weight: 700;
		}

		&__value {
			font-size: 40px;
		}

		&__unit {
			font-size: 18px;
		}

		.__red {
			color: $red;
		}
	
		.__orange {
			color: $orange;
		}
	
		.__yellow {
			color: $yellow;
		}
	}

	.b-tooltip {
		display: inline-block;
		margin-left: 10px;
	}

	.b-indicator--custom {
		.b-indicator__value {
			@include font(900, Humanist);
			color: #808285;
			font-size: 54px;
			line-height: 65px;
			margin-bottom: 8px;
			display: block;
		}

		.b-indicator__unit {
			@include font(400, Humanist);
			color: #808285;
			font-size: 18px;
			line-height: 20px;
			font-weight: 400;
			margin-bottom: 12px;
			display: block;
		}
	}
	
	a {
		&:hover {
			background-color: white;

			&>.__grey {
				color: $light-grey;
			}
		}
	}

	.operation {
		max-width: 100%;
		width: 1580px;
		margin: 0 auto;
		box-sizing: border-box;
		@include font(400, FranklinGothic);
		overflow: hidden;
	
		// @media screen and (max-width: 768px) {
		// 	padding: 0 24px;
		// }
	
		&:after {
			height: 100%;
			width: 1px;
			background-color: $light-grey;
			position: absolute;
			right: 271px;
			top: 0;
	
			@media screen and (min-width: 1024px) {
				content: "";
			}
		}
	
		&__title {
			font-size: 38px;
			text-transform: uppercase;
			@include font(900, Humanist);
			margin-bottom: 58px;
		}
	
		// &__left {
		// 	flex-grow: 1;
		// 	padding-right: 97px;
	
		// 	@media screen and (max-width: 1023px) {
		// 		padding-right: 0;
		// 		width: 100%;
		// 	}
		// }
	
		// &__right {
		// 	min-width: 224px;
		// 	width: 224px;
	
		// 	@media screen and (max-width: 1023px) {
		// 		width: 100%;
		// 	}
		// }
	
		// &__input {
		// 	display: flex;
		// 	margin-bottom: 52px;
	
		// 	&-title {
		// 		font-size: 28px;
		// 		line-height: 120%;
		// 		text-transform: uppercase;
		// 		@include font(900, Humanist);
		// 		margin-bottom: 28px;
		// 	}
	
		// 	&-wrap {
		// 		display: flex;
		// 	}
	
		// 	&-block {
		// 		margin: 16px;
	
		// 		&:first-child {
		// 			margin-left: 0;
		// 		}
	
		// 		&:last-child {
		// 			margin-right: 0;
		// 		}
	
		// 		img {
		// 			max-width: 100%;
		// 		}
		// 	}
	
		// 	&-caption {
		// 		font-size: 20px;
		// 		line-height: 120%;
		// 		@include font(900, Humanist);
		// 		margin: 24px 0 4px;
	
		// 		span {
		// 			width: 26px;
		// 			height: 26px;
		// 			display: inline-flex;
		// 			justify-content: center;
		// 			align-items: center;
		// 			background-color: $yellow;
		// 			cursor: pointer;
		// 			position: relative;
		// 			color: $white;
	
		// 			&:before {
		// 				content: attr(data-tooltip-text);
		// 				position: absolute;
		// 				background-color: $yellow;
		// 				padding: 8px 16px;
		// 				width: 300px;
		// 				font-size: 12px;
		// 				line-height: 130%;
		// 				@include font(700, Humanist);
		// 				bottom: 44px;
		// 				left: 50%;
		// 				transform: translateX(-50%);
		// 				transition: .15s;
		// 				opacity: 0;
		// 				visibility: hidden;
		// 			}
	
		// 			&:after {
		// 				content: '';
		// 				width: 0;
		// 				height: 0;
		// 				border: 6px solid transparent;
		// 				border-top-color: $yellow;
		// 				position: absolute;
		// 				bottom: 32px;
		// 				left: 50%;
		// 				transform: translateX(-50%);
		// 				transition: .15s;
		// 				opacity: 0;
		// 				visibility: hidden;
		// 			}
	
		// 			&:hover {
		// 				&:before,
		// 				&:after {
		// 					visibility: visible;
		// 					opacity: 1;
		// 				}
		// 			}
		// 		}
		// 	}
	
		// 	&-number {
		// 		color: $grey;
		// 		font-size: 54px;
		// 		line-height: 65px;
		// 		@include font(900, Humanist);
		// 		margin-bottom: 8px;
		// 	}
	
		// 	&-description {
		// 		color: $grey;
		// 		font-size: 18px;
		// 		line-height: 20px;
		// 		@include font(400, FranklinGothic);
		// 		margin-bottom: 12px;
		// 	}
	
		// 	@media screen and (max-width: 1023px) {
		// 		flex-direction: column;
	
		// 		&-wrap {
		// 			flex-wrap: wrap;
		// 			justify-content: space-between;
		// 		}
	
		// 		&-block {
		// 			width: calc(50% - 16px);
		// 			margin: 0 0 16px;
		// 		}
	
		// 		&-number {
		// 			font-size: 40px;
		// 			margin-bottom: 0;
		// 			line-height: 44px;
		// 		}
		// 	}
	
		// 	@media screen and (max-width: 539px) {
		// 		&-block {
		// 			width: 100%;
	
		// 			img {
		// 				display: block;
		// 				margin: 0 auto;
		// 			}
		// 		}
		// 	}
		// }
	
		&__segment {
			@include font(900, Humanist);
			margin-bottom: 63px;
			display: flex;
			align-items: flex-start;
	
			&-title {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				border-bottom: 5px solid transparent;
				padding-bottom: 8px;
				margin-bottom: 38px;
	
				&.__red {
					border-color: $red;
				}
	
				&.__orange {
					border-color: $orange;
				}
	
				&.__yellow {
					border-color: $yellow;
				}
	
				& > span {
					font-size: 32px;
					line-height: 100%;
				}
	
				span {
					&.__red {
						color: $red;
					}
	
					&.__gray {
						color: $grey;
					}
	
					&.__orange {
						color: $orange;
					}
	
					&.__yellow {
						color: $yellow;
					}
				}
	
				a {
					font-size: 16px;
					line-height: 120%;
					text-decoration: none;
					cursor: pointer;
					color: #000;
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-end;
					margin-left: 12px;
	
					span {
						margin-left: 6px;
					}
				}
			}
	
			&-tabs {
				nav {
					display: flex;
					justify-content: space-between;
					align-items: center;
					border: 0;
				}
	
				&-item {
					cursor: pointer;
	
					svg {
						width: 80px;
						height: 80px;
					}
	
					a {
						display: flex;
						flex-direction: column;
						align-items: center;
						background-color: white !important;
						color: black;
						text-decoration: none;
	
						&:hover, &:visited:hover {
							color: black;
	
							& > div {
								border-bottom-color: $grey;
								color: $grey;
							}
	
							svg {
								fill: $grey;
							}
						}
					}
				}
	
				&-description {
					height: 59px;
					font-size: 20px;
					line-height: 100%;
					@include font(900, Humanist);
					display: flex;
					align-items: center;
					text-align: center;
					border-bottom: 3px solid transparent;
				}
	
				&-between {
					flex-grow: 1;
					border-bottom: 2px dotted transparent;
					margin: 0 12px;
					position: relative;
					top: -30px;
	
					&:after {
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 7px 0 8px 19px;
						border-color: transparent;
						content: "";
						position: absolute;
						right: -3px;
						top: -6px;
					}
				}
	
				&.__red {
					.operation__segment-tabs-between {
						border-color: $red;
	
						&:after {
							border-left-color: $red;
						}
					}
	
					a.is-expand {
						svg {
							fill: $red;
						}
	
						.operation__segment-tabs-description {
							border-bottom-color: $red;
							color: $red;
						}
					}
				}
	
				&.__orange {
					.operation__segment-tabs-between {
						border-color: $orange;
	
						&:after {
							border-left-color: $orange;
						}
					}
	
					a.is-expand {
						svg {
							fill: $orange;
						}
	
						.operation__segment-tabs-description {
							border-bottom-color: $orange;
							color: $orange;
						}
					}
				}
	
				&.__yellow {
					.operation__segment-tabs-between {
						border-color: $yellow;
	
						&:after {
							border-left-color: $yellow;
						}
					}
	
					a.is-expand {
						svg {
							fill: $yellow;
						}
	
						.operation__segment-tabs-description {
							border-bottom-color: $yellow;
							color: $yellow;
						}
					}
				}
			}
	
			&-list {
				border-width: 1px 1px 0 0;
				border-color: $grey;
				border-style: solid;
				padding: 25px 33px 25px 0;
				font-size: 18px;
				line-height: 140%;
				display: none;
				@include font(400, FranklinGothic);
	
				img {
					max-width: 100%;
				}
	
				ul {
					margin: 0;
					padding: 0;
					list-style-type: none;
	
					li {
						display: flex;
						justify-content: space-between;
						align-items: flex-end;
						padding: 0;
	
						&:before {
							content: none;
						}
	
						span {
							&.__line {
								border-bottom: 1px dotted $grey;
								flex-grow: 1;
	
								@media screen and (max-width: 768px) {
									display: none;
								}
							}
	
							&:first-child {
								margin-right: 6px;
							}
	
							&:last-child {
								white-space: nowrap;
							}
						}
	
						&.__pin {
							color: $dark-grey;
	
							span:first-child:before {
								content: '';
								width: 7px;
								height: 7px;
								display: inline-block;
								margin-right: 8px;
								border-radius: 100%;
								background-color: $red;
							}
						}
	
						&.__color-orange {
							color: $orange;
	
							span:first-child:before {
								background-color: $orange;
							}
						}
	
						&.__color-red {
							color: $red;
						}
					}
				}
	
				p {
					margin-top: 24px;
					margin-bottom: 0;
				}
	
				&.is-expand {
					display: block;
				}
			}
	
			@media screen and (max-width: 1023px) {
				flex-direction: column;
			}
	
			@media screen and (max-width: 768px) {
				&-title {
					margin-bottom: 8px;
				}
	
				&-tabs {
					flex-direction: column;
	
					&-item {
						width: 100%;
						display: flex;
						justify-content: center;
						border-bottom: 1px solid grey;
					}
	
					&-description {
						height: 43px;
					}
	
					&-between,
					br,
					svg {
						display: none;
					}
				}
	
				&-list {
					border: 0;
					padding: 20px 0 0;
				}
			}
		}
	
		&__aside {
			margin-top: 9px;
	
			&-title {
				font-size: 18px;
				@include font(900, Humanist);
	
				span {
					font-size: 40px;
					line-height: 48px;
				}
	
				&.__red {
					color: $red;
				}
	
				&.__orange {
					color: $orange;
				}
	
				&.__yellow {
					color: $yellow;
				}
			}
	
			&-desc {
				font-size: 14px;
				line-height: 17px;
				@include font(900, Humanist);
				margin-bottom: 14px;
				display: flex;
				align-items: center;
	
				img {
					margin: 0;
	
					&.__rotate-180 {
						transform: rotate(180deg);
					}
				}
			}
	
			&-text {
				font-size: 16px;
				line-height: 120%;
				@include font(400, FranklinGothic);
				color: $dark-grey;
			}
		}
	}
}

// .l-page__nav-controls.custom-navigation {
// 	right: 260px;
// 	z-index: 1;

// 	@media screen and (max-width: 1023px) {
// 		display: none;
// 	}

// 	// &.__fixed {
// 	// 	position: fixed;
// 	// 	top: calc(50% - 46px);
// 	// 	right: 340px;
// 	// }
// }



@media screen and (max-width: 1023px) {
	.l-page__nav-controls.custom-navigation {
		display: none;
	}

	.body--operational .b-pagenav--bottom {
		display: block;
	}
}
