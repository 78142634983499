$search--input-color: $color-mine-shaft;
$search--message-color: $t_tooltips--color;
$search--message-bg: $t_tooltips--bg-color;
$search--result-bg: #eeeeee;
$search--result-item-border: #cccccc;
$search--result-button-color: #666666;
$search--result-button-color-hover: #fff;
$search--result-button-bg: #cccccc;
$search--result-button-bg-hover: #666666;
$search--label-color: $color-ironside-gray;
$search--btn-bg: $color-celeste;
$search--btn-bg-hover: $color-tundora;
$search--btn-bg-active: $color-tundora;
$search--btn-icon: $color-grey-opacity;
$search--btn-icon-hover: $color-eclipse;
$search--btn-icon-active: $color-eclipse;
$search--btn-icon-close: $color-black;
$search--btn-icon-close-hover: $color-tundora;
$search--btn-color: $base-text-color;
$search--btn-color-hover: $color-white;
$search--btn-color-active: $color-white;
$search--speech-btn-icon: #444444;
$search--speech-btn-icon-hover: $color-black;
$search--speech-btn-icon-active: $color-carrot-orange;
$search--speech-btn-bg: transparent;
$search--speech-btn-bg-hover: transparent;
$search--speech-btn-bg-active: transparent;
$search--speech-btn-icon-record: #eb4834;
$search--speech-btn-icon-record-sonar: #eb4834;
$search--close-btn-icon: $color-eclipse;
$search--close-btn-icon-hover: $color-eclipse;
$search--close-btn-icon-active: $color-eclipse;
$search--close-btn-bg: transparent;
$search--close-btn-bg-hover: transparent;
$search--close-btn-bg-active: transparent;

@if ($contrast) {
	$search--input-color: $color-mine-shaft;
	$search--message-color: $color-white;
	$search--message-bg: $color-ironside-gray;
	$search--result-bg: #eeeeee;
	$search--result-item-border: #cccccc;
	$search--result-button-color: #666666;
	$search--result-button-color-hover: #fff;
	$search--result-button-bg: #cccccc;
	$search--result-button-bg-hover: #666666;
	$search--label-color: $color-ironside-gray;
	$search--btn-bg: #ededed;
	$search--btn-bg-hover: #656565;
	$search--btn-bg-active: #343434;
	$search--btn-icon: #656565;
	$search--btn-icon-hover: $color-white;
	$search--btn-color: #656565;
	$search--btn-color-hover: $color-white;
	$search--btn-color-active: $color-white;
	$search--speech-btn-icon: #444444;
	$search--speech-btn-icon-hover: $color-black;
	$search--speech-btn-icon-active: $color-carrot-orange;
	$search--speech-btn-bg: transparent;
	$search--speech-btn-bg-hover: transparent;
	$search--speech-btn-bg-active: transparent;
}

.b-search__input {
	@include font-size(15px);
	color: $search--input-color;
	padding-right: 24px;

	&::-ms-clear {
		display: none;
		width : 0;
		height: 0;
	}
}

.b-search__message {
	display: none;
	width: auto;
	position: absolute;
	@include rem(top, -30px);
	left: 0;
	color: $search--message-color;
	padding: 5px;
	background-color: $search--message-bg;
	cursor: default;
	@include corner(bottom, $search--message-bg, 0.455em);
	@include font-size(11px);
	white-space: nowrap;

	&--bottom {
		top: auto;
		@include rem(bottom, -30px);
		@include corner(top, $search--message-bg, 0.455em);

		&:after {
			content: none;
		}
	}
}

.search__additional-buttons {
	position: absolute;
	display: flex;
	align-items: center;
	right: 0;
	top: 0;
	bottom: 0;
}

.b-search__button-close {
	@include webfont-icon($webfont-icon--i-close);
	color: $search--close-btn-icon;
	background-color: $search--close-btn-bg;
	@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

	&:before {
		display: block;
		font-size: 14px;
		z-index: 1;
		opacity: 0.7;
	}

	&:hover,
	&:focus {
		color: $search--close-btn-icon-hover;
		background-color: $search--close-btn-bg-hover;
		&:before {
			opacity: 1;
		}
	}

	&:active,
	&.is-expand {
		color: $search--close-btn-icon-active;
		background-color: $search--close-btn-bg-active;
		&:before {
			opacity: 1;
		}
	}
}

.b-search__label {
	@extend .user-select--no;
}

@keyframes sonarWave {
	to {
		transform: scale(3);
		opacity: 0;
	}
}

.b-search__input-container {
	position: relative;
	overflow: hidden;
	height: 100%;
}

.b-search__button-speech {
	position: relative;
	@include webfont-icon($webfont-icon--microphone);
	color: $search--speech-btn-icon;
	background-color: $search--speech-btn-bg;
	@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

	&:before {
		display: block;
		z-index: 1;
		font-size: 18px;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 12px;
		height: 12px;
		border-radius: 20px;
		background-color: $search--speech-btn-icon-record-sonar;
		transform: scale(0);
		opacity: 0;
		pointer-events: none;
	}

	&:hover,
	&:focus {
		color: $search--speech-btn-icon-hover;
		background-color: $search--speech-btn-bg-hover;
	}

	&:active,
	&.is-expand {
		color: $search--speech-btn-icon-active;
		background-color: $search--speech-btn-bg-active;
	}

	&.is-recording {
		color: $search--speech-btn-icon-record;

		&:after {
			opacity: 0.35;
			animation: sonarWave 1.5s linear infinite;
		}
	}

	.feature-no-speechrecognition & {
		display: none;
	}
}

.b-search {
	&__result {
		display: none;
		position: absolute;
		margin: auto;
		background-color: $search--result-bg;
		overflow: hidden;
		z-index: 20;
		width: 100%;
	}

	&__result-item {
		cursor: pointer;
		display: block;
		@include rem(border-width, 1px);
		border-style: solid;
		border-color: $search--result-item-border;
		text-decoration: none;
		@include rem(margin-top, 5px);
		@include rem(padding-left, 10px);
		@include rem(padding-right, 10px);
		@include rem(padding-bottom, 5px);
	}

	&__result-button {
		display: block;
		@include rem(width, 130px);
		@include rem(height, 30px);
		@include rem(left, 10px);
		@include rem(bottom, 10px);
		background-color: $search--result-button-bg;
		color: $search--result-button-color;
		@include rem(line-height, 30px);
		text-decoration: none;
		@include rem(margin, 10px);
		text-align: center;

		&:visited {
			color: $search--result-button-color;
		}

		&:hover {
			background-color: $search--result-button-bg-hover;
			color: $search--result-button-color-hover !important;
		}
	}
}
