blockquote {
	position: relative;
	margin-bottom: 35px;
	color: $color-black;

	.text {
		position: relative;
		padding: 30px 60px 30px 80px;
		@include webfont-icon($webfont-icon--i-quote);
		@include font(600, 'FranklinGothic');
		background-color: $color-supernova-light;

		&:before {
			position: absolute;
			font-size: 43px;
			top: 0;
			left: 0;
			color: $color-black;
		}

		.reportType--csr & {
			font-family: Arial;
			background-color: $color-supernova-kco;
		}
	}

	.person {
		margin-top: 17px;

		&__name {
			display: block;
			font-size: 18px;
			color: $color-black;
		}

		&__position {
			display: block;
		}

		&__company {
			display: block;
		}
	}
}

.quote--photo {
	display: flex;
	padding: 0;
	flex-direction: column;
    padding-bottom: 30px;
	position: relative;
	align-items: flex-end;

	.quote__photo {
		flex-shrink: 0;
		width: 64%;

		img {
			display: block;
		}
	}

	.quote__content .text {
		width: 42%;
		min-width: 400px;
		position: absolute;
		padding: 42px 60px 45px 80px;
		bottom: 0;
		left: 0;
	}
}

// @include breakpoint(sm) {
// 	blockquote {
// 		padding: 24px 16px 24px 40px;

// 		.text {
// 			&:before {
// 				left: -28px;
// 			}
// 		}
// 	}

// 	.quote--photo {
// 		display: block;
// 		padding: 24px 16px 24px 40px;

// 		.quote__photo {
// 			width: 95%;
// 			margin-bottom: 25px;
// 		}

// 		.quote__content {
// 			position: relative;
// 			padding: 0;
// 		}
// 	}
// }

@media (max-width: $breakpoint-lg) and (min-width: 1400px) {
	.quote--photo {
		.quote__photo {
			width: 60%;
		}

		.quote__content .text {
			margin-left: 12%;
		}
	}
}

@media (max-width: 1200px) and (min-width: $breakpoint-md) {
	.quote--photo {
		.quote__photo {
			width: 90%;
		}

		.quote__content .text {
			margin-left: -110px;
		}
	}
}

@media (max-width: 970px) {
	.quote--photo {
		.quote__photo {
			width: 100%;
			margin-bottom: 0;
		}
		.quote__content {
			position: relative;
			padding: 0;
			width: 100%;
		}
		.quote__content .text {
			width: 100%;
			min-width: auto;
			position: static;
			padding: 42px 20px 20px 40px;
			bottom: auto;
			left: 0;

			&:before {
				top: 5px;
				left: 5px;
				font-size: 29px;
			}
		}
	}
}
